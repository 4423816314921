import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { DataGuardComponent } from '@iot-platform/feature/data-guard';
import { GridEngineModule } from '@iot-platform/grid-engine';
import { IotPlatformDashboardsModule } from '@iot-platform/iot-platform-dashboards';
import { ContactFormModule, DefaultCardComponent, EventSeverityDisplayModule, IotPlatformUiModule } from '@iot-platform/iot-platform-ui';
import { NotificationModule } from '@iot-platform/notification';
import { DateFormatPipe, EntityDisplayPipe, InfoDisplayPipe, LinkifyPipe } from '@iot-platform/pipes';
import { EntitiesService, SharedModule } from '@iot-platform/shared';
import { SitesService, SiteTypesService } from '@iot-platform/shared/services';
import { TableEngineModule } from '@iot-platform/table-engine';
import { TranslateModule } from '@ngx-translate/core';
import { AssetDeviceAssociationsModule } from '../../../../components/asset-device-associations/asset-device-associations.module';
import { EventDetailPopupModule } from '../../../../components/event-detail-popup/event-detail-popup.module';
import { EventsByConceptGridModule } from '../../../../components/events-by-concept-grid/events-by-concept-grid.module';
import { AssetEventsApi } from '../../../asset-events/+state/asset-events.api';
import { DeviceEventsApi } from '../../../device-events/+state/device-events.api';
import { ContactAssociatedNotificationsExportsPopupComponent } from '../../components/contact-associated-notifications-exports-popup/contact-associated-notifications-exports-popup.component';
import { ContactManageNotificationsFormComponent } from '../../components/contact-manage-notifications-form/contact-manage-notifications-form.component';
import { ExportSpreadsheetCreationFormModule } from '../../components/export-spreadsheet-creation-form/export-spreadsheet-creation-form.module';
import { NotificationCreationFormModule } from '../../components/notification-creation-form/notification-creation-form.module';
import { SiteCardComponent } from '../../components/site-card/site-card.component';
import { NotificationsExportsTabsCheckboxListComponent } from '../../components/site-contacts-tab/notifications-exports-tabs/notifications-exports-tabs-checkbox-list/notifications-exports-tabs-checkbox-list.component';
import { NotificationsExportsTabsRelatedContactsComponent } from '../../components/site-contacts-tab/notifications-exports-tabs/notifications-exports-tabs-related-contacts/notifications-exports-tabs-related-contacts.component';
import { SiteContactsTabComponent } from '../../components/site-contacts-tab/site-contacts-tab.component';
import { SiteEventsTabComponent } from '../../components/site-events-tab/site-events-tab.component';
import { SiteExportSpreadsheetsComponent } from '../../components/site-export-spreadsheets/site-export-spreadsheets.component';
import { SiteInfoFormComponent } from '../../components/site-info-form/site-info-form.component';
import { SiteInfoComponent } from '../../components/site-info/site-info.component';
import { SiteNotificationsComponent } from '../../components/site-notifications/site-notifications.component';
import { MultiSelectChipModule } from './../../../../components/multi-select-chip/multi-select-chip.module';
import { SiteOverviewComponent } from './site-overview.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    IotPlatformUiModule,
    TranslateModule,
    DefaultCardComponent,
    RouterModule,
    AssetDeviceAssociationsModule,
    TableEngineModule,
    NotificationModule,
    EventDetailPopupModule,
    IotPlatformDashboardsModule,
    MultiSelectChipModule,
    ContactFormModule,
    NotificationCreationFormModule,
    ExportSpreadsheetCreationFormModule,
    LinkifyPipe,
    GridEngineModule,
    EventSeverityDisplayModule,
    NotificationsExportsTabsCheckboxListComponent,
    NotificationsExportsTabsRelatedContactsComponent,
    EventsByConceptGridModule,
    InfoDisplayPipe,
    EntityDisplayPipe,
    DateFormatPipe,
    DataGuardComponent
  ],
  declarations: [
    SiteCardComponent,
    SiteInfoComponent,
    SiteOverviewComponent,
    SiteInfoFormComponent,
    SiteContactsTabComponent,
    SiteNotificationsComponent,
    ContactManageNotificationsFormComponent,
    SiteExportSpreadsheetsComponent,
    ContactAssociatedNotificationsExportsPopupComponent,
    SiteEventsTabComponent
  ],
  providers: [EntitiesService, SiteTypesService, SitesService, AssetEventsApi, DeviceEventsApi],
  exports: [SiteCardComponent, SiteInfoComponent, SiteOverviewComponent]
})
export class SiteOverviewModule {}
