@if (settings.header.enabled()) {
  <section data-cy="data-guard--header" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px" class="data-guard--header">
    @if (settings.header.svgIcon()) {
      <mat-icon [svgIcon]="settings.header.svgIcon()"></mat-icon>
    }
    @if (settings.header.title()) {
      <span fxFlex fxLayout="row" fxLayoutAlign="start center" class="data-guard--header-title fw-600">
        {{ settings.header.title() | translate }}
        @if (settings.header.infoIcon()) {
          &nbsp;
          <mat-icon [matTooltip]="settings.header.infoTooltip() | translate" matTooltipClass="regular-tooltip">info_outlined</mat-icon>
        }
      </span>
    }
    @if (settings.header.closeButton()) {
      <button mat-icon-button class="regular-round-button" (click)="closeDrawerClicked()">
        <mat-icon>close</mat-icon>
      </button>
    }
  </section>
}
<section>
  @if (settings.content.tabs) {
    <mat-tab-group color="accent">
      @for (tab of settings.content.tabs | keyvalue; track tab.key) {
        <mat-tab [label]="tab.key">
          <ng-template matTabLabel>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px">
              <span>{{ tab.value.label() | translate | uppercase }}</span>
              @if (tab.value.containsError()) {
                <div class="data-guard--error-indicator"></div>
              }
            </div>
          </ng-template>
          <ng-template matTabContent>
            @if (this[tab.key]) {
              <ng-container *ngTemplateOutlet="this[tab.key]" />
            }
          </ng-template>
        </mat-tab>
      }
    </mat-tab-group>
  }
</section>

<ng-template #overview>
  <iot-platform-feature-data-guard-overview-checks-tab
    (dispatchEvent)="onDispatchOverviewTabEvent($event)"
    [loading]="overviewChecksLoading()"
    [overviewChecks]="overviewChecks()"
  />
</ng-template>
