import { KeyValuePipe, NgComponentOutlet, NgTemplateOutlet, UpperCasePipe } from '@angular/common';
import { Component, computed, inject, Signal, TemplateRef, ViewChild } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { OverviewCardComponent } from '@iot-platform/iot-platform-ui';
import { DataGuardCheck, DataGuardEvent } from '@iot-platform/models/data-guard';
import { TranslateModule } from '@ngx-translate/core';
import { OverviewCheckComponent } from '../../components/overview-check/overview-check.component';
import { OverviewChecksTabComponent } from '../../components/overview-checks-tab/overview-checks-tab.component';
import { DataGuardSettings } from '../../models';
import { AbstractDataGuardService } from '../../services/abstract-data-guard.service';
import { DATA_GUARD_SETTINGS } from '../../settings/data-guard.provider';

@Component({
  selector: 'iot-platform-feature-data-guard',
  standalone: true,
  imports: [
    FlexLayoutModule,
    MatIcon,
    MatTabsModule,
    TranslateModule,
    UpperCasePipe,
    MatTooltipModule,
    MatButtonModule,
    KeyValuePipe,
    MatProgressSpinner,
    OverviewCardComponent,
    OverviewCheckComponent,
    NgComponentOutlet,
    OverviewChecksTabComponent,
    NgTemplateOutlet
  ],
  templateUrl: './data-guard.component.html',
  styleUrl: './data-guard.component.scss'
})
export class DataGuardComponent {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @ViewChild('overview') overview: TemplateRef<any>;

  protected readonly abstractService: AbstractDataGuardService = inject(AbstractDataGuardService);
  overviewChecks: Signal<DataGuardCheck[]> = toSignal(this.abstractService.getDataGuardOverviewChecks());
  overviewChecksLoading = computed(() => {
    const checks = this.overviewChecks();
    return !checks;
  });

  protected readonly settings: DataGuardSettings = inject(DATA_GUARD_SETTINGS);

  closeDrawerClicked(): void {
    this.abstractService.closeButtonClick();
  }

  onDispatchOverviewTabEvent(event: DataGuardEvent): void {
    this.abstractService.dispatchDataGuardEvent(event);
  }
}
