<form [formGroup]="inputForm" fxFlex>
  <mat-form-field color="accent" fxFlex>
    <mat-label>{{ 'PO_EVENTS.CREATION_CONFIGURATION_POPUP.INPUT_LIST.' + (placeholder$ | async) | translate }}</mat-label>
    <input (change)="onValueChange()" (keyup)="onValueChange()" formControlName="value" matInput required type="number" />
    @if (minValue) {
      <mat-hint align="start">{{ 'PO_EVENTS.CREATION_CONFIGURATION_POPUP.MIN' | translate }}&nbsp;{{ minValue }} </mat-hint>
    }
    @if (maxValue) {
      <mat-hint align="end">{{ 'PO_EVENTS.CREATION_CONFIGURATION_POPUP.MAX' | translate }}&nbsp;{{ maxValue }} </mat-hint>
    }
    @if (value.hasError('min')) {
      <mat-error align="start">{{ 'PO_EVENTS.CREATION_CONFIGURATION_POPUP.ERROR_MIN' | translate }}</mat-error>
    }
    @if (value.hasError('max')) {
      <mat-error align="start">{{ 'PO_EVENTS.CREATION_CONFIGURATION_POPUP.ERROR_MAX' | translate }}</mat-error>
    }
    @if (value.hasError('required')) {
      <mat-error align="start">{{ 'PO_EVENTS.CREATION_CONFIGURATION_POPUP.REQUIRED' | translate }}</mat-error>
    }
  </mat-form-field>
</form>
