<div fxFlex fxLayout="column">
  <section fxFlex fxLayout="row" fxLayoutAlign="start center">
    <div
      (click)="onClickBack()"
      [matTooltip]="'PO_EVENTS.INFO_PAGE.BACK_BUTTON' | translate"
      class="origin"
      fxFlex
      fxLayoutAlign="center center"
      matTooltipClass="regular-tooltip"
    >
      <mat-icon>keyboard_backspace</mat-icon>
    </div>
    <iot-platform-ui-toolbar-v2
      (dispatchToolbarEvent)="onToolbarEvent($event)"
      [breadCrumbConfiguration]="{ entityName: selectedRule()?.entity.name, icon: 'poeventrules' }"
      [buttonList]="ruleDetailButtonList"
      [name]="selectedRule()?.name"
      [size]="toolbarSize"
      fxFlex="100"
    >
    </iot-platform-ui-toolbar-v2>
  </section>
  <mat-tab-group #matTabGroup (selectedTabChange)="onSelectedTabChange($event)" color="accent" mat-align-tabs="center">
    <mat-tab label="tab_info">
      <ng-template matTabLabel>
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="2px">
          <span>{{ 'IOT_DICTIONARY.INFORMATION' | translate | uppercase }}</span>
        </div>
      </ng-template>
      <ng-template matTabContent>
        <iot4bos-ui-po-event-info
          (configureRule)="configureRule()"
          (editRule)="editRule()"
          [canUpdateRule]="canUpdateRule"
          [selectedRule]="selectedRule()"
        ></iot4bos-ui-po-event-info>
      </ng-template>
    </mat-tab>
    @if (canReadEvent) {
      <mat-tab label="tab_events" [disabled]="!selectedRule()?.concept || !selectedRule()">
        <ng-template matTabLabel>
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="2px">
            <span>{{ 'IOT_DICTIONARY.EVENTS' | translate | uppercase }}</span>
          </div>
        </ng-template>
        <ng-template matTabContent>
          <iot4bos-ui-po-event-generated-events-tab
            fxFlex
            [rule]="selectedRule()"
            [userPermissions]="userPermissions"
          ></iot4bos-ui-po-event-generated-events-tab>
        </ng-template>
      </mat-tab>
    }
  </mat-tab-group>
</div>
