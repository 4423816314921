import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MAT_PAGINATOR_DEFAULT_OPTIONS, MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DateFormatPipe } from '@iot-platform/pipes';

import { IconModule } from '@iot-platform/shared/components';

import { TranslateModule } from '@ngx-translate/core';

import { MasterViewCellModule } from '../../table/cells/master-view-cell.module';
import { MasterViewHeaderModule } from '../../table/header-cells/master-view-header.module';
import { MasterViewEngineService } from '../master-view-engine.service';
import { MasterViewExpandModule } from './master-view-expand/master-view-expand.module';
import { MasterViewComponentFactory } from './master-view-factory';
import { TableFullHeightDirective } from './master-view-table-full-height.directive';
import { MasterViewTableComponent } from './master-view-table.component';

@NgModule({
  declarations: [MasterViewTableComponent],
  providers: [
    MasterViewComponentFactory,
    MasterViewEngineService,
    {
      provide: MAT_PAGINATOR_DEFAULT_OPTIONS,
      useValue: {
        formFieldAppearance: 'fill'
      }
    }
  ],
  imports: [
    CommonModule,
    TranslateModule,
    DateFormatPipe,
    FlexModule,
    MatSortModule,
    MatTableModule,
    MatTooltipModule,
    IconModule,
    MatPaginatorModule,
    MasterViewHeaderModule,
    MasterViewCellModule,
    MasterViewExpandModule,
    MatCheckboxModule,
    MatMenuModule,
    MatButtonModule,
    MatIconModule,
    MatProgressSpinnerModule,
    TableFullHeightDirective
  ],
  exports: [MasterViewTableComponent]
})
export class MasterViewTableModule {}
