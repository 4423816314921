<div class="full-width container" fxLayout="column" fxLayoutAlign="start stretch">
  <div [fxLayout]="displayBy === 'assets' ? 'row' : 'row-reverse'" class="association-toolbars" fxLayoutAlign="space-between center" fxLayoutGap="30px">
    <div fxFlex="50%" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
      <span
        (click)="onUpdateUserPreferences('assets')"
        [matTooltip]="(displayBy === 'assets' ? 'SITES.ASSOCIATIONS.DISPLAYED_BY_ASSETS' : 'SITES.ASSOCIATIONS.DISPLAY_BY_ASSETS') | translate"
        [ngClass]="{ 'display-by-toggle': displayBy !== 'assets', 'display-by-active': displayBy === 'assets' }"
        fxLayoutAlign="start center"
        fxLayoutGap="10px"
        matTooltipClass="regular-tooltip"
        matTooltipPosition="below"
      >
        <mat-icon [color]="displayBy === 'assets' ? 'accent' : '#b0b0b0'" svgIcon="asset"></mat-icon>
        <span class="association-toolbars-title">{{ 'SITES.ASSOCIATIONS.ASSETS' | translate }}</span>
      </span>

      <hr class="regular-1px-line" fxFlex />

      @if (canCreateAsset && !navigationMode) {
        <button class="light-button" mat-mini-fab [matMenuTriggerFor]="menu">
          <mat-icon>add</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button (click)="dispatchAction.emit({ action: ACTIONS.CREATE })" [disabled]="true" mat-menu-item>
            {{ 'ASSETS.SHELL.CREATE_ASSET' | translate }}
          </button>
          <button (click)="dispatchAction.emit({ action: ACTIONS.CREATE_FROM_TEMPLATE })" mat-menu-item>
            {{ 'ASSETS.SHELL.CREATE_ASSET_FROM_TEMPLATE' | translate }}
          </button>
        </mat-menu>
      }
    </div>

    <div fxFlex="50%" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
      <span
        (click)="onUpdateUserPreferences('devices')"
        [matTooltip]="(displayBy === 'devices' ? 'SITES.ASSOCIATIONS.DISPLAYED_BY_DEVICES' : 'SITES.ASSOCIATIONS.DISPLAY_BY_DEVICES') | translate"
        [ngClass]="{ 'display-by-toggle': displayBy !== 'devices', 'display-by-active': displayBy === 'devices' }"
        class="display-by-toggle"
        fxLayoutAlign="start center"
        fxLayoutGap="10px"
        matTooltipClass="regular-tooltip"
        matTooltipPosition="below"
      >
        <mat-icon [color]="displayBy === 'devices' ? 'accent' : '#b0b0b0'" svgIcon="device"></mat-icon>
        <span class="association-toolbars-title">{{ 'SITES.ASSOCIATIONS.DEVICES' | translate }}</span>
      </span>
      <hr class="regular-1px-line" fxFlex />
      @if (canCreateDevice && !navigationMode) {
        <button
          (click)="dispatchAction.emit({ action: 'add-device' })"
          [matTooltip]="'SITES.ASSOCIATIONS.ADD_DEVICE' | translate"
          class="light-button"
          mat-mini-fab
          matTooltipClass="regular-tooltip"
          matTooltipPosition="below"
        >
          <mat-icon>add</mat-icon>
        </button>
      }
    </div>
  </div>

  @if (!associationsLoaded) {
    <section>
      <div [fxLayout]="displayBy === 'assets' ? 'row' : 'row-reverse'" fxLayoutAlign="space-between center" fxLayoutGap="30px">
        <iot-platform-ui-card-loader [backgroundClass]="'default-card h-82'" fxFlex></iot-platform-ui-card-loader>
        <iot-platform-ui-card-loader [backgroundClass]="'default-card h-82'" fxFlex></iot-platform-ui-card-loader>
      </div>
    </section>
  }
  <section [ngClass]="{ 'association-section': navigationMode && associationsLoaded, invisible: !associationsLoaded, visible: associationsLoaded }">
    @if (!navigationMode && ((!hasAssets && canCreateAsset) || (!hasDevices && canCreateDevice))) {
      <div [fxLayout]="displayBy === 'assets' ? 'row' : 'row-reverse'" class="association-section" fxLayoutAlign="space-between center" fxLayoutGap="30px">
        @if (!hasAssets && canCreateAsset) {
          <iot-platform-ui-default-card fxFlex [backgroundClass]="'h-82'" [title]="'SITES.ASSOCIATIONS.ADD_ASSET' | translate"></iot-platform-ui-default-card>
        }

        @if (!hasDevices && canCreateDevice) {
          <iot-platform-ui-default-card
            fxFlex
            (click)="dispatchAction.emit({ action: 'add-device' })"
            [backgroundClass]="'h-82'"
            [title]="'SITES.ASSOCIATIONS.ADD_DEVICE' | translate"
          ></iot-platform-ui-default-card>
        }
      </div>
    }
    @if (associationsLoaded && associations[displayBy].length) {
      @for (association of associations[displayBy]; track association) {
        <div class="association-section">
          <iot4bos-ui-asset-device-association-item
            (dispatchAction)="dispatchAction.emit($event)"
            [association]="association"
            [canCreateAsset]="canCreateAsset"
            [canCreateDevice]="canCreateDevice"
            [displayBy]="displayBy"
            [navigationMode]="navigationMode"
            [selectedItemId]="selectedItem?.id"
          ></iot4bos-ui-asset-device-association-item>
        </div>
      }
    }
  </section>
</div>
