import { EnvironmentProviders, makeEnvironmentProviders, signal, Type } from '@angular/core';
import { AbstractDataGuardService, DataGuardSettings, DataGuardSettingsFactory, provideDataGuardSettingsFactory } from '@iot-platform/feature/data-guard';
import { Asset, Device, Site } from '@iot-platform/models/i4b';
import { AbstractI4BDataGuardService } from '../../../services/abstract-i4b-data-guard.service';
import { DataGuardAssetService } from './data-guard-asset.service';
import { DataGuardDeviceService } from './data-guard-device.service';
import { DataGuardSiteService } from './data-guard-site.service';

function getEnvironmentProviders<T, U, S extends AbstractI4BDataGuardService<T, U>>(service): EnvironmentProviders {
  return makeEnvironmentProviders([
    provideDataGuardSettingsFactory({
      dataGuardService: service,
      settingsFactory: ({ dataGuardService }): Partial<DataGuardSettings> => ({
        header: {
          enabled: signal(true),
          title: signal('DATA_GUARD.DRAWER_TITLE'),
          svgIcon: signal('data_guard'),
          infoIcon: signal(true),
          infoTooltip: signal('DATA_GUARD.TOOLTIP'),
          closeButton: signal(true)
        },
        content: {
          tabs: {
            overview: {
              label: signal('DATA_GUARD.TABS.OVERVIEW'),
              containsError: (dataGuardService as unknown as S).overviewContainsError
            }
          }
        }
      })
    } as {
      dataGuardService: Type<AbstractDataGuardService>;
      settingsFactory: DataGuardSettingsFactory;
    })
  ]);
}

export const dataGuardSiteProvider: EnvironmentProviders = getEnvironmentProviders<Site, Site, DataGuardSiteService>(DataGuardSiteService);
export const dataGuardAssetProvider: EnvironmentProviders = getEnvironmentProviders<Asset, Site, DataGuardAssetService>(DataGuardAssetService);
export const dataGuardDeviceProvider: EnvironmentProviders = getEnvironmentProviders<Device, Site, DataGuardDeviceService>(DataGuardDeviceService);
