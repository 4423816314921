import { computed, inject, Injectable, Signal } from '@angular/core';
import { ENVIRONMENT } from '@iot-platform/core';
import { Environment } from '@iot-platform/models/common';
import { DataGuardCheck } from '@iot-platform/models/data-guard';
import { Device, Site } from '@iot-platform/models/i4b';
import { NavigationApi } from '../../+state/navigation.api';
import { AbstractI4BDataGuardService } from '../../../services/abstract-i4b-data-guard.service';

@Injectable({
  providedIn: 'root'
})
export class DataGuardDeviceService extends AbstractI4BDataGuardService<Device, Site> {
  private readonly environment: Environment = inject(ENVIRONMENT);
  getDataGuardOverviewChecksURL: Signal<string> = computed(() => {
    const site = this.overviewElementToCheck();
    if (site) {
      return `${this.environment.api.url}${this.environment.api.endpoints.dataGuardChecks}${this.environment.api.endpoints.sites}/${site.id}`;
    }
  });
  getDataGuardChecksByElementIdURL: Signal<string> = computed(() => {
    const device = this.elementToCheck();
    if (device) {
      return `${this.environment.api.url}${this.environment.api.endpoints.devices}/${device.id}${this.environment.api.endpoints.dataGuardChecks}`;
    }
  });
  private readonly navigationApi: NavigationApi = inject(NavigationApi);
  overviewElementToCheck = this.navigationApi.site;
  elementToCheck = this.navigationApi.selectedDevice;

  closeButtonClick(): void {
    this.navigationApi.toggleDataGuard(false);
  }

  navigateTo(check: DataGuardCheck): void {
    if (check.source.concept === 'asset') {
      this.navigationApi.selectAsset(this.overviewElementToCheck().id, check.id);
      this.navigationApi.toggleDataGuard(false);
    } else if (check.source.concept === 'device' && !!check.id) {
      this.navigationApi.selectDevice(this.overviewElementToCheck().id, check.id);
      this.navigationApi.toggleDataGuard(false);
    } else if (check.source.concept === 'site') {
      this.navigationApi.selectLeSite({ id: check.id } as Site);
      this.navigationApi.toggleDataGuard(false);
    }
  }
}
