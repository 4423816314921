import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { LocalStorageKeys, LocalStorageService } from '@iot-platform/core';

import { AssetVariable, AssetVariableThreshold, DeviceVariable } from '@iot-platform/models/i4b';
import { TimeseriesWidgetOptions } from '@iot-platform/models/widgets';

import { DateFormatPipe } from '@iot-platform/pipes';
import { TranslateService } from '@ngx-translate/core';
import { Options, SeriesOptionsType } from 'highcharts';
import * as Highcharts from 'highcharts/highcharts';
import HC_export_data from 'highcharts/modules/export-data';
import HC_exporting from 'highcharts/modules/exporting';
import HC_NoData from 'highcharts/modules/no-data-to-display';
import { flatten } from 'lodash';
import * as moment from 'moment';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { SelectedVariableChart } from './models/selected-variable-chart';
import { VariableChart } from './models/variable-chart';
import { getVariable } from './utils/variable-chart.utils';
import { VariableChartService } from './variable-chart.service';

HC_exporting(Highcharts);
HC_export_data(Highcharts);
HC_NoData(Highcharts);

/* eslint-disable @typescript-eslint/dot-notation */
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'iot4bos-ui-variable-chart',
  templateUrl: './variable-chart.component.html',
  styleUrls: ['./variable-chart.component.scss']
})
export class VariableChartComponent implements OnInit, OnDestroy {
  @Input() data = [];
  @Input() variableType!: string;
  @Input() options: TimeseriesWidgetOptions;

  Highcharts: typeof Highcharts = Highcharts;
  chartConstructorType = 'chart';
  // inner variable
  variables: VariableChart[] = [];
  allVariables: DeviceVariable[] | AssetVariable[] = [];
  filteredVariables: (DeviceVariable | AssetVariable)[] = [];
  // Manage charts data
  selectedVariables$: BehaviorSubject<SelectedVariableChart> = new BehaviorSubject<SelectedVariableChart>({
    variables: [],
    start: '',
    end: '',
    limit: 50000
  });
  commonChartOptions: Options = {
    title: { text: '' },
    navigator: { enabled: false },
    navigation: { buttonOptions: { enabled: false } },
    credits: { enabled: false },
    rangeSelector: { enabled: false, inputEnabled: false }
  };
  mainChartOptions$: BehaviorSubject<Options> = new BehaviorSubject<Options>(this.commonChartOptions);
  previewChartOptions$: BehaviorSubject<Options> = new BehaviorSubject<Options>({
    ...this.commonChartOptions,
    plotOptions: {
      series: {
        animation: {
          duration: 0
        },
        events: {
          afterAnimate: () => this.reflow()
        }
      }
    },
    exporting: { buttons: { contextButton: { enabled: false } } }
  });
  scale$: BehaviorSubject<number | null> = new BehaviorSubject<number | null>(null);
  timezone = '';
  userNumberLocale = JSON.parse(this.storage.get(LocalStorageKeys.STORAGE_USER_PREFERENCES))?.appNumberFormat ?? 'en';
  thousandSep = '';
  decimalPoint = '';
  // End of manage charts data
  scalableVariables: string[] = ['%', 'BAR', 'V', 'A', 'INH2O', 'M3'];
  // left sidenav data
  mainVariableSeries = [];
  // are sidenav opened
  variablesOpened = false;
  displayAllVariablesLoader = false;
  // display scale button
  scalable = true;
  // display loader
  displayLoader = false;
  // chart selected period
  chartPeriod?: number;
  // thresholds line variables
  deviceVariableThresholdDefaultColorList: string[] = ['#FFF2CC', '#FAC7A7', '#F59B81', '#F06F5B', '#EA4335'];
  assetVariableThresholdDefaultColorList: string[] = ['#FFF500', '#F58C2D', '#E75D70', '#C76CCF', '#963ADE'];
  maxThresholds = 5;
  thresholds: { value: number; color: string; width: number; zIndex: number; label: { text: string } }[] = [];
  highchartsDateFormat = JSON.parse(this.storage.get(LocalStorageKeys.STORAGE_USER_PREFERENCES))?.appDateFormats?.highchartsFullFormat ?? '%Y-%m-%d %H:%M:%S';
  momentNoTimeFormat = JSON.parse(this.storage.get(LocalStorageKeys.STORAGE_USER_PREFERENCES))?.appDateFormats?.momentNoTime ?? 'yyyy-MM-DD';
  initialTableSortIsAscending = false;
  destroy$ = new Subject<void>();
  @ViewChild('highChart') highChart: ElementRef;
  fullscreenOn = false;
  private variablesFilter;

  constructor(
    private readonly dateFormatPipe: DateFormatPipe,
    private readonly variableChartService: VariableChartService,
    private readonly translateService: TranslateService,
    private readonly storage: LocalStorageService
  ) {}

  get displaySearchBar(): boolean {
    if (this.options && this.options.chart && this.options.chart.searchBar) {
      return this.options.chart.searchBar.enabled;
    }
    return true;
  }

  ngOnInit() {
    this.setLocaleFormat(this.userNumberLocale);
    this.variables = this.data.reduce((acc: VariableChart[], value: AssetVariable, index: number) => {
      // For graphs, thresholds are set and displayed only for the first variable aka the reference variable
      if (index === 0) {
        if (this.variableType === 'assetVariable') {
          this.setAssetVariableThresholds(value);
        } else if (this.variableType === 'deviceVariable') {
          this.setDeviceVariableThresholds(value);
        }
      }
      return [...acc, getVariable(value)];
    }, []);

    if (this.variables[0]?.unit && this.scalableVariables.includes(this.variables[0]?.unit.toUpperCase())) {
      this.scalable = true;
      this.scale$ = new BehaviorSubject<number>(0);
    } else {
      this.scale$ = new BehaviorSubject<null>(null);
    }

    this.timezone = JSON.parse(this.storage.get(LocalStorageKeys.STORAGE_BUSINESS_PROFILE_KEY))?.timezoneDetails?.name ?? 'utc';
    const chartPeriod = (this.chartPeriod = JSON.parse(this.storage.get(LocalStorageKeys.STORAGE_BUSINESS_PROFILE_KEY))?.chartPeriod);
    const today = moment().toISOString(false);
    const start = moment().subtract(chartPeriod, 'days').toISOString(false);

    const variables$ = this.selectedVariables$.pipe(
      switchMap((req: SelectedVariableChart) => {
        this.displayLoader = true;
        return this.variableChartService.loadTimeseriesByVariables(req.variables, req.start, req.end, req.limit);
      })
    );
    const scalable$ = this.scale$;
    const execute$ = combineLatest([variables$, scalable$]);

    execute$.pipe(takeUntil(this.destroy$)).subscribe(([vars, scale]) => {
      this.displayLoader = false;

      const newSeriz = [];

      this.mainVariableSeries = { ...vars[0].series };

      const uniqueUnits = vars
        .map((variable) => variable.unit)
        .reduce((acc, current) => {
          if (!acc.includes(current)) {
            acc.push(current);
          }
          return acc;
        }, []);

      vars.forEach((variable) => {
        const timeseries = Object.entries(variable.series).map((timeserie) => [parseInt(timeserie[0], 10), timeserie[1]]) as [number, number][];
        const sortedTimeseries = [...timeseries].sort();

        const addedSerie: SeriesOptionsType = {
          name: variable.name,
          type: 'line',
          data: sortedTimeseries,
          tooltip: {
            valueSuffix: variable.unit,
            valueDecimals: JSON.parse(this.storage.get(LocalStorageKeys.STORAGE_USER_PREFERENCES))?.appNumberOfDecimals,
            xDateFormat: this.highchartsDateFormat
          },
          yAxis: uniqueUnits.findIndex((unit) => unit === variable.unit)
        };

        if (this.options && this.options.chart && this.options.chart.legend && this.options.chart.legend.labelFormat) {
          addedSerie.name = this.options.chart.legend.labelFormat(variable);
        }
        newSeriz.push(addedSerie);
      });
      this.mainChartOptions$.next(this.getChartOptions(vars[0], scale, newSeriz, uniqueUnits));
      this.previewChartOptions$.next(this.getChartOptions(vars[0], scale, newSeriz, uniqueUnits));
      // lock chart y unit to the data.variable
    });

    this.selectedVariables$.next({ variables: [...this.variables], start, end: today, limit: 50000 });
  }

  setLocaleFormat(locale: string): void {
    switch (locale) {
      case 'fr':
        this.thousandSep = ' ';
        this.decimalPoint = ',';
        break;
      case 'en':
      default:
        this.thousandSep = ',';
        this.decimalPoint = '.';
        break;
    }

    Highcharts.setOptions({
      lang: {
        thousandsSep: this.thousandSep,
        decimalPoint: this.decimalPoint,
        noData: 'Loading data...'
      }
    });
  }

  setAssetVariableThresholds(variable: AssetVariable): void {
    for (let i = 0; i < this.maxThresholds; i++) {
      let currentThreshold: AssetVariableThreshold | undefined;

      if (!!variable.thresholds?.values?.length && !!variable.thresholds?.values[0].position) {
        currentThreshold = variable.thresholds.values.find((t) => t.position === i + 1);
      }

      if (!!variable.thresholds?.values?.length && !variable.thresholds?.values[0].position) {
        currentThreshold = variable.thresholds?.values[i];
      }

      if (currentThreshold) {
        this.thresholds.push({
          value: currentThreshold.value,
          color: currentThreshold.lineColor ?? this.assetVariableThresholdDefaultColorList[i],
          zIndex: 4 + i,
          width: 1,
          label: {
            text: this.translateService?.instant('VARIABLE_CHART.LABEL_ASSET_VARIABLE_THRESHOLD', {
              thresholdOperator: variable.thresholds?.operator,
              thresholdName: currentThreshold.name,
              variableName: variable.name
            })
          }
        });
      }
    }
  }

  setDeviceVariableThresholds(variable): void {
    for (let i = 0; i < this.maxThresholds; i++) {
      const thresholdParameterName: string = 'threshold' + (i + 1).toString();
      if (variable[thresholdParameterName] !== null && variable[thresholdParameterName] !== undefined) {
        this.thresholds.push({
          value: variable[thresholdParameterName],
          color: this.deviceVariableThresholdDefaultColorList[i],
          zIndex: 4 + i,
          width: 1,
          label: {
            text: this.translateService?.instant('VARIABLE_CHART.LABEL_DEVICE_VARIABLE_THRESHOLD', {
              thresholdNumber: i + 1,
              variableName: variable.name
            })
          }
        });
      }
    }
  }

  getChartOptions(variable: VariableChart, scale: number, series: SeriesOptionsType[], units: any[]): Highcharts.Options {
    return {
      chart: {
        style: {
          fontFamily: '"Lucida Grande", "Lucida Sans Unicode", Arial, Helvetica, sans-serif',
          fontSize: '12px'
        },
        // zoomType: 'x',
        zooming: {
          type: 'x'
        }
      },
      time: {
        moment,
        timezone: this.timezone
      } as never,
      xAxis: {
        type: 'datetime',
        dateTimeLabelFormats: {
          millisecond: '%H:%M:%S.%L',
          second: '%H:%M:%S',
          minute: '%H:%M',
          hour: '%H:%M',
          day: '%e. %b %y',
          week: '%e. %b %y',
          month: '%b %y',
          year: '%Y'
        }
      },
      yAxis: units.map((unit, index) => ({
        labels: {
          format: `{value} ${unit}`,
          style: {
            // eslint-disable-next-line @typescript-eslint/no-base-to-string
            color: `${Highcharts.getOptions().colors[index]}`
          }
        },
        title: {
          text: '',
          style: {
            // eslint-disable-next-line @typescript-eslint/no-base-to-string
            color: `${Highcharts.getOptions().colors[index]}`
          }
        },
        opposite: index !== 0,
        min: scale,
        max:
          scale !== null
            ? Math.max(
                // eslint-disable-next-line prefer-spread
                Math.max.apply(
                  Math,
                  this.thresholds.map((e) => e.value)
                ),
                // eslint-disable-next-line prefer-spread
                Math.max.apply(
                  Math,
                  flatten(series.map((e: any) => e.data)).map((e) => e[1])
                )
              )
            : null,
        plotLines: index === 0 ? this.thresholds : []
      })),
      tooltip: {
        shared: true
      },
      series,
      navigator: {
        enabled: false
      },
      navigation: {
        buttonOptions: {
          enabled: true
        }
      },
      credits: { enabled: false },
      rangeSelector: {
        enabled: false,
        inputEnabled: false
      },
      legend: {
        enabled: this.options && this.options.chart && this.options.chart.legend ? this.options.chart.legend.enabled : true
      },
      exporting: {
        enabled: this.options && this.options.chart && this.options.chart.export ? this.options.chart.export.enabled : true,
        filename: `${this.getCurrentTime()}-${variable.parentName}-${variable.name}`,
        showTable: false,
        csv: {
          itemDelimiter: ';',
          dateFormat: this.highchartsDateFormat,
          decimalPoint: this.decimalPoint,
          columnHeaderFormatter(item: any) {
            if (item.name) {
              if (item.options?.tooltip?.valueSuffix) {
                return `${item.name} (${item.options.tooltip.valueSuffix})`;
              }
              return item.name;
            }
            return 'DateTime';
          }
        },
        buttons: {
          contextButton: {
            menuItems: ['downloadCSV', 'downloadXLS']
          }
        }
      }
    };
  }

  getCurrentTime(): string {
    return this.dateFormatPipe.transform(Date.now(), this.momentNoTimeFormat);
  }

  downloadFile(file: any[], fileName: string) {
    const blob = new Blob(file, { endings: 'transparent', type: 'data:text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('href', url);
    a.setAttribute('download', `${fileName}.csv`);
    a.click();
  }

  toggleVariable(variable: DeviceVariable | AssetVariable, checked: boolean): void {
    const transformed: VariableChart = getVariable(variable);
    const previous = this.selectedVariables$.getValue();
    const newVariables: VariableChart[] = [...previous.variables];

    if (checked) {
      newVariables.push(transformed);
    } else {
      newVariables.splice(
        newVariables.findIndex((v) => v.id === transformed.id),
        1
      );
    }
    this.selectedVariables$.next({
      variables: [...newVariables],
      start: previous.start,
      end: previous.end,
      limit: previous.limit
    });
  }

  onSelectPeriod(period: number): void {
    this.chartPeriod = period;
    const today = moment().toISOString(false);
    const start = moment().subtract(period, 'days').toISOString(false);
    const previous = this.selectedVariables$.getValue();
    this.selectedVariables$.next({ variables: previous.variables, start, end: today, limit: previous.limit });
  }

  getSelectedVariables(variable: DeviceVariable | AssetVariable): boolean {
    if (this.selectedVariables$) {
      const selected = this.selectedVariables$.getValue().variables;
      return selected.findIndex((v) => v.id === variable.id) !== -1;
    }
    return false;
  }

  getLockedVariables(variable: DeviceVariable | AssetVariable): boolean {
    return !!this.data.find((v: DeviceVariable | AssetVariable) => v.id === variable.id);
  }

  onSelectDates(startDate: string, endDate: string): void {
    this.chartPeriod = 0;
    const start = moment(startDate).toISOString(false);
    const end = endDate ? moment(endDate).toISOString(false) : moment().toISOString(false);
    const previous = this.selectedVariables$.getValue();
    this.selectedVariables$.next({ variables: previous.variables, start, end, limit: previous.limit });
  }

  onAutoScaleChange(event: MatSlideToggleChange): void {
    this.scale$.next(event.checked ? null : 0);
  }

  closeNav(): void {
    this.variablesOpened = false;
  }

  openVariables(): void {
    this.variablesOpened = true;
    this.displayAllVariablesLoader = true;
    this.variableChartService
      .loadVariables(this.variables)
      .pipe(takeUntil(this.destroy$))
      .subscribe((variables) => {
        this.allVariables = variables;
        this.displayAllVariablesLoader = false;
        this.filteredVariables = [...variables];
      });
  }

  filterVariables(event, listToFilter: DeviceVariable[] | AssetVariable[]): void {
    this.variablesFilter = event;
    this.filteredVariables = this.getFilteredVariables(listToFilter, this.variablesFilter);
  }

  getFilteredVariables(listToFilter: any[], filterEvent): DeviceVariable[] | AssetVariable[] {
    return listToFilter.filter((variable) => variable.name.toLowerCase().includes(filterEvent.target.value.toLowerCase()));
  }

  setFullscreenOn(fullscreenOn: boolean) {
    this.fullscreenOn = fullscreenOn;
  }

  reflow() {
    this.highChart['chart']['reflow']();
  }

  toggleTableView(displayTable: boolean) {
    if (displayTable) {
      this.highChart['chart'].viewData();
    } else {
      this.highChart['chart'].hideData();
    }

    if (displayTable) {
      document.querySelectorAll('thead th').forEach((th, index) => {
        if (index === 0) {
          this.sortViewTable(th, this.initialTableSortIsAscending);
        }

        let isSortAscending = this.initialTableSortIsAscending;

        th.addEventListener('click', () => {
          isSortAscending = !isSortAscending;
          this.sortViewTable(th, isSortAscending);
        });
      });
    }
  }

  sortViewTable(th: Element, isSortChronological: boolean): void {
    const table = th.closest('table');
    const tbody = table?.querySelector('tbody');
    Array.from(tbody?.querySelectorAll('tr') as NodeListOf<HTMLTableRowElement>)
      .sort(this.comparer(Array.from(th.parentNode.children).indexOf(th), isSortChronological))
      .forEach((tr) => tbody?.appendChild(tr));
  }

  comparer = (idx, asc) => (a, b) =>
    ((v1, v2) => (v1 !== '' && v2 !== '' && !isNaN(v1) && !isNaN(v2) ? v1 - v2 : v1.toString().localeCompare(v2)))(
      this.getCellValue(asc ? a : b, idx),
      this.getCellValue(asc ? b : a, idx)
    );

  getCellValue(tr, idx) {
    if (tr.children[idx].className === 'highcharts-text') {
      return moment(
        tr.children[idx].innerText,
        JSON.parse(this.storage.get(LocalStorageKeys.STORAGE_USER_PREFERENCES))?.appDateFormats?.momentFullFormat ?? this.dateFormatPipe.defaultFormat
      ).valueOf();
    }
    return tr.children[idx].innerText || tr.children[idx].textContent;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
