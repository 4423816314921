import { Injectable } from '@angular/core';
import { AbstractGridAssetTemplatesService } from '@iot-platform/grid-engine';
import { Entity } from '@iot-platform/models/common';
import { AssetTemplate } from '@iot-platform/models/i4b';
import { AssetTemplateHelpers } from '@iot-platform/util/assets';

@Injectable({
  providedIn: 'root'
})
export class GridAssetTemplatesService extends AbstractGridAssetTemplatesService {
  calculateIsEditable(template: AssetTemplate, sessionEntity: Entity): boolean {
    return AssetTemplateHelpers.calculateIsEditable(template, sessionEntity);
  }
}
