import { inject, Injectable, signal, Signal, WritableSignal } from '@angular/core';
import { AnalyticsService, LocalStorageKeys, LocalStorageService } from '@iot-platform/core';
import { AbstractMasterViewMapService } from '@iot-platform/feature/master-view';
import { IotMapActionType, IotMapDisplayMode, IotMapEvent, IotMapFacade, MapNavigationEvent, MapNavigationModelType } from '@iot-platform/iot-platform-maps';
import { Device, Site } from '@iot-platform/models/i4b';
import { NavigationApi } from '../../../../containers/+state/navigation.api';

@Injectable({
  providedIn: 'root'
})
export class DevicesMasterViewMapService extends AbstractMasterViewMapService {
  analytic: AnalyticsService = new AnalyticsService('devices_shell');
  displayMode: WritableSignal<IotMapDisplayMode> = signal('ccf');
  protected readonly mapFacade: IotMapFacade = inject(IotMapFacade);
  protected readonly navigationApi: NavigationApi = inject(NavigationApi);
  protected readonly storage: LocalStorageService = inject(LocalStorageService);

  getMapConcept(): Signal<string> {
    return signal('devices');
  }

  getMapDisplayMode(): Signal<IotMapDisplayMode> {
    const mapDisplayMode = (this.storage.get('devices-display-mode') ?? 'ccf') as IotMapDisplayMode;
    this.displayMode.set(mapDisplayMode);
    return this.displayMode;
  }

  onDispatchMapNavigationEvent(event: MapNavigationEvent<MapNavigationModelType>): void {
    this.storage.set(LocalStorageKeys.STORAGE_MV_ORIGIN_KEY, 'device');
    if (event.element && event.destination === 'site') {
      this.analytic.log('map_actions', 'navigate_to_site');
      this.navigationApi.selectLeSite(event.element as Site);
    } else if (event.destination === 'device') {
      this.analytic.log('map_actions', 'navigate_to_device');
      this.navigationApi.openDeviceDetail(event.element as Device);
    }
  }

  onMapEvent(event: IotMapEvent): void {
    if (event.type === IotMapActionType.CHANGE_DISPLAY_MODE && event.displayMode !== undefined) {
      this.displayMode.set(event.displayMode);
      this.analytic.log('map_actions', 'change_display_mode', event.displayMode);
      this.storage.set('devices-display-mode', event.displayMode);
    }
  }

  refreshMap(): void {
    this.mapFacade.refreshMap();
  }
}
