import { Type } from '@angular/core';

export interface OverViewCardValueMeta {
  visibleExpression: (data?: any) => boolean;
  icon: string;
  tooltip: string;
}

export interface OverViewCardAction {
  icon?: string;
  label?: string;
  type: OverviewCardActions;
  tooltip?: string;
  disabled?: boolean;
  visible?: boolean;
  divider?: boolean;
  valueMeta?: OverViewCardValueMeta;
}

export enum OverviewCardActions {
  EDIT = 'EDIT',
  CONFIGURE_PRODUCTS = 'CONFIGURE_PRODUCTS',
  REMOVE_PRODUCTS = 'REMOVE_PRODUCTS',
  CONFIGURE_PROPERTIES = 'CONFIGURE_PROPERTIES',
  EXPORT_TO_JSON = 'EXPORT_TO_JSON'
}

interface OverViewCardCommonOptions {
  key: string;
  cssClass: string;
  style: any;
  data: any;
  visibleExpression: (data?: any) => boolean;
}

interface OverViewCardSectionProperty extends OverViewCardCommonOptions {
  label: string;
  value: unknown;
  valueMeta: OverViewCardValueMeta;
  width: string;
  componentRef: {
    ref: Type<any>;
    inputs: any;
  };
  onClick?: (event?: any) => void;
}

export interface OverViewCardSection extends OverViewCardCommonOptions {
  title: string;
  collapsible: boolean;
  expandedChange: (index: number, expanded: boolean) => void;
  expanded: boolean;
  action: OverViewCardAction & { dispatchEvent: (data?: any) => void };
  properties: Partial<OverViewCardSectionProperty>[];
}

export interface ExpansionPanelsOptions {
  enableCache: boolean;
  clearOnDestroy: boolean;
  key: string;
  entityId?: string;
}

export interface OverviewCard {
  name?: string;
  valueMeta?: OverViewCardValueMeta;
  nameMetaAction?: OverViewCardAction & { dispatchEvent: (data?: any) => void };
  expansionPanelsOptions?: ExpansionPanelsOptions;
  image?: string | null;
  defaultImage?: string | null;
  defaultSvgIcon?: string | null;
  cssClass?: string;
  justifyContent?: boolean;
  sections: Partial<OverViewCardSection>[];
  actions?: OverViewCardAction[];
}
