import { Component, effect, inject, OnInit, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AbstractControl, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { Filter, FilterDynamicListSingleSelectOptions } from '@iot-platform/models/common';
import { DynamicListFieldService } from '@iot-platform/shared/services';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { uniqBy } from 'lodash';
import { finalize } from 'rxjs/operators';
import { AsyncAutocompleteMultipleSelectsComponent } from '../../../../async-autocomplete-multiple-selects/async-autocomplete-multiple-selects.component';
import { AsyncAutocompleteComponent } from '../../../../async-autocomplete/async-autocomplete.component';
import { AbstractFilterEngineFieldComponent } from '../../abstract-filter-engine-field.component';

@Component({
  standalone: true,
  imports: [
    FlexLayoutModule,
    TranslateModule,
    ReactiveFormsModule,
    AsyncAutocompleteComponent,
    AsyncAutocompleteMultipleSelectsComponent,
    MatFormFieldModule,
    MatProgressSpinner,
    MatSelectModule
  ],
  selector: 'iot-platform-ui-dynamic-list-field-single-select',
  templateUrl: './dynamic-list-field-single-select.component.html',
  styleUrls: ['./dynamic-list-field-single-select.component.scss']
})
export class DynamicListFieldSingleSelectComponent extends AbstractFilterEngineFieldComponent<FilterDynamicListSingleSelectOptions> implements OnInit {
  private readonly dynamicListFieldService: DynamicListFieldService = inject(DynamicListFieldService);
  private readonly translateService: TranslateService = inject(TranslateService);
  form: UntypedFormGroup = new UntypedFormGroup({
    select: new UntypedFormControl('')
  });
  dynamicList = signal([]);
  showLoader = signal(false);
  selectedFilters = signal([]);

  get select(): AbstractControl {
    return this.form.get('select');
  }

  ngOnInit() {
    this.loadDynamicList();
    this.initSelectedFilterEffect();
  }

  initSelectedFilterEffect() {
    effect(
      () => {
        const data = this.data();
        const filters = this.currentFilters();
        if (data.multiSelect) {
          const selectedFilters = [];
          if (filters.filter((f) => f.criteriaKey === data.criteriaKey).length > 0) {
            filters.forEach((filter: Filter) => {
              if (data.filterBy && data.labelToDisplay) {
                selectedFilters.push({ id: filter.value, value: filter.label });
              } else {
                selectedFilters.push(filter.value);
              }
            });
          }
          this.select.setValue(selectedFilters);
          this.selectedFilters.set(selectedFilters);
        }
      },
      { allowSignalWrites: true, injector: this.injector }
    );
  }

  onSelectionChange(value?: any): void {
    const data = this.data();
    const dynamicList = this.dynamicList();
    const selectedFilters = this.selectedFilters();
    const filter: Filter = {};
    filter.criteriaKey = data.criteriaKey;
    filter.criteriaLabel = data.criteriaLabel;
    filter.data = dynamicList;
    filter.displayWrapper = data.displayWrapper;

    if (!data.multiSelect) {
      if (data.autocomplete) {
        filter.value = data.filterBy ? value[data.filterBy] : value;
        filter.label = data.labelToDisplay ? value[data.labelToDisplay] : value;
      } else {
        filter.value = data.filterBy ? this.select.value[data.filterBy] : this.select.value;
        filter.label = data.labelToDisplay ? this.select.value[data.labelToDisplay] : this.select.value;
      }
    } else {
      if (data.autocomplete) {
        filter.value = data.filterBy ? value[data.filterBy] : value;
        filter.label = data.labelToDisplay ? value[data.labelToDisplay] : value;
      } else {
        let diff;
        if (selectedFilters.length > this.select.value.length) {
          diff = this.getDifference(selectedFilters, this.select.value);
          this.currentFiltersSize.update((v) => v--);
        } else {
          diff = this.getDifference(this.select.value, selectedFilters);
          this.currentFiltersSize.update((v) => v++);
        }
        filter.value = data.filterBy ? diff[data.filterBy] : diff;
        filter.label = data.labelToDisplay ? diff[data.labelToDisplay] : diff;
        this.selectedFilters.set([...this.select.value]);
      }
    }

    const filterHidden: Filter = {};
    if (data.includeSubEntities) {
      filterHidden.criteriaKey = 'includeSubEntities';
      filterHidden.criteriaLabel = '';
      filterHidden.label = '';
      filterHidden.value = true;
      filterHidden.isHidden = true;
    }

    this.dispatchFilterEvent(data.includeSubEntities ? [filter, filterHidden] : [filter]);

    if (!data.multiSelect) {
      this.form.reset();
    }
  }

  isSelectedOption(option): boolean {
    return !!this.selectedFilters().find((f) => f === option);
  }

  getOptionToDisplay(option): string {
    const data = this.data();
    let dataToDisplay = option;
    if (data.selectByProperty) {
      dataToDisplay = option[data.selectByProperty];
    }
    if (data.translatedKey) {
      dataToDisplay = this.translateService.instant(data.translatedKey + dataToDisplay);
    }
    return dataToDisplay;
  }

  getDifference(array1: any, array2: any): any {
    const data = this.data();
    return array1.filter((obj1: any) => !array2.some((obj2: any) => (data.filterBy && data.labelToDisplay ? obj1.id === obj2.id : obj1 === obj2)))[0];
  }

  compareFn(filter1: any, filter2: any): boolean {
    if (filter1.id && filter2.id) {
      return filter1.id === filter2.id;
    } else if (!filter1.id && filter2.id) {
      return filter1 === filter2.id;
    } else {
      return filter1 === filter2;
    }
  }

  private loadDynamicList() {
    this.showLoader.set(true);
    const data = this.data();
    this.dynamicList.set([]);
    this.dynamicListFieldService
      .getDynamicList(data.url, data?.sortMethod, data.arrayOrObject)
      .pipe(
        finalize(() => {
          this.showLoader.set(false);
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((list: Array<any>) => {
        const values = data.distinctValues ? uniqBy(list, data.selectByProperty) : list;
        this.dynamicList.set(data.dataTransform ? data.dataTransform(values) : values);
      });
  }
}
