import { HttpClient } from '@angular/common/http';
import { inject, Injectable, signal, Signal, WritableSignal } from '@angular/core';
import { AbstractDataGuardService } from '@iot-platform/feature/data-guard';
import { CommonGenericModel } from '@iot-platform/models/common';
import { DataGuardCheck, DataGuardDetailedChecksApiResponse, DataGuardEventType, DataGuardOverviewChecksApiResponse } from '@iot-platform/models/data-guard';
import { noop, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

enum DataGuardOverviewChecksApiResponseKey {
  site = 'site',
  assets = 'assets',
  devices = 'devices'
}
@Injectable({
  providedIn: 'root'
})
export abstract class AbstractI4BDataGuardService<T extends CommonGenericModel, K extends CommonGenericModel> extends AbstractDataGuardService {
  abstract elementToCheck: Signal<T>;
  abstract overviewElementToCheck: Signal<K>;
  abstract getDataGuardChecksByElementIdURL: Signal<string>;
  abstract getDataGuardOverviewChecksURL: Signal<string>;
  overviewContainsError: WritableSignal<boolean> = signal(false);
  private readonly http: HttpClient = inject(HttpClient);

  getDataGuardChecksByElementId(): Observable<DataGuardDetailedChecksApiResponse> {
    if (this.elementToCheck()) {
      return this.http.get<DataGuardDetailedChecksApiResponse>(this.getDataGuardChecksByElementIdURL());
    } else {
      return of(undefined);
    }
  }

  static getSourceFromBackEndGrouping(key: DataGuardOverviewChecksApiResponseKey): DataGuardCheck['source'] {
    switch (key) {
      case DataGuardOverviewChecksApiResponseKey.site:
        return { concept: 'site', icon: 'site' };
      case DataGuardOverviewChecksApiResponseKey.assets:
        return { concept: 'asset', icon: 'asset' };
      case DataGuardOverviewChecksApiResponseKey.devices:
        return { concept: 'device', icon: 'device' };
      default:
        return { concept: key, icon: key };
    }
  }

  getDataGuardOverviewChecks(): Observable<DataGuardCheck[]> {
    if (this.overviewElementToCheck()) {
      return this.http.get<DataGuardOverviewChecksApiResponse>(this.getDataGuardOverviewChecksURL()).pipe(
        map((apiResponse: DataGuardOverviewChecksApiResponse) => {
          const apiResponseKeys: DataGuardOverviewChecksApiResponseKey[] = Object.keys(apiResponse) as DataGuardOverviewChecksApiResponseKey[];
          return apiResponseKeys.reduce((acc: DataGuardCheck[], apiResponseKey: DataGuardOverviewChecksApiResponseKey) => {
            const elementsByConcept = Object.entries(apiResponse[apiResponseKey]);
            const processedChecks: DataGuardCheck[] = elementsByConcept.map((e) => ({
              id: this.elementToCheck().id === e[0] ? null : e[0],
              name: e[1].name,
              status: e[1].status,
              source: AbstractI4BDataGuardService.getSourceFromBackEndGrouping(apiResponseKey)
            }));
            acc.push(...processedChecks);
            this.overviewContainsError.update((value) => {
              value = value || !!processedChecks.filter((c) => c.status === 'warning' || c.status === 'error').length;
              return value;
            });
            return acc;
          }, []);
        })
      );
    } else {
      return of(undefined);
    }
  }

  closeButtonClick(): void {
    noop();
  }

  dispatchDataGuardEvent(event): void {
    switch (event.type) {
      case DataGuardEventType.CLICK_ON_CHECK:
        this.navigateTo(event.check);
        break;
      default:
        break;
    }
  }

  abstract navigateTo(check: DataGuardCheck): void;
}
