import { ModuleWithProviders, NgModule } from '@angular/core';
import { SortEntitiesUtil } from '@iot-platform/iot-platform-utils';
import { DynamicListFieldService } from '@iot-platform/shared/services';
import { merge } from 'lodash';
import {
  CountryAutocompleteFieldComponent,
  DateIntervalFieldComponent,
  DynamicListFieldMultipleSelectsComponent,
  DynamicListFieldSingleSelectComponent,
  InputFieldComponent,
  MultipleInputsFieldComponent,
  SelectFieldComponent,
  TimezoneAutocompleteFieldComponent
} from './fields';
import { FilterComponentFactory } from './filter-component-factory';
import { FILTER_ENGINE_SETTINGS } from './filter-engine-settings.providers';
import { FilterEngineComponent } from './filter-engine.component';
import { ManageFavoriteFiltersPopupComponent } from './manage-favorite-filters-popup/manage-favorite-filters-popup.component';
import { FilterEngineSettings } from './models';
import { AbstractFavoriteFilterEngineService } from './services/abstract-favorite-filter-engine.service';
import { FilterEngineSettingsService } from './services/filter-engine-settings.service';
import { FilterEngineService } from './services/filter-engine.service';

@NgModule({
  imports: [
    FilterEngineComponent,
    InputFieldComponent,
    MultipleInputsFieldComponent,
    SelectFieldComponent,
    DateIntervalFieldComponent,
    DynamicListFieldSingleSelectComponent,
    DynamicListFieldMultipleSelectsComponent,
    TimezoneAutocompleteFieldComponent,
    CountryAutocompleteFieldComponent,
    ManageFavoriteFiltersPopupComponent
  ],
  providers: [FilterEngineService, FilterComponentFactory, DynamicListFieldService, SortEntitiesUtil, FilterEngineSettingsService],
  exports: [FilterEngineComponent]
})
export class FilterEngineModule {
  private static currentSettings: FilterEngineSettings;

  static withSettings(settings: Partial<FilterEngineSettings>): ModuleWithProviders<FilterEngineModule> {
    if (settings) {
      this.currentSettings = merge(this.currentSettings, settings);
    }
    const providers = [];
    if (settings?.favoriteFilterEngineService) {
      providers.push({
        provide: AbstractFavoriteFilterEngineService,
        useClass: settings.favoriteFilterEngineService
      });
    }
    return {
      ngModule: FilterEngineModule,
      providers: [
        ...providers,
        {
          provide: FILTER_ENGINE_SETTINGS,
          useValue: {
            ...this.currentSettings
          }
        }
      ]
    };
  }
}
