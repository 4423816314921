import { Component, input, output, signal, WritableSignal } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AbstractControl, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
// FIXME this dependency should be reviewed it causes an app crash
import { ProgressBarButtonModule } from 'libs/iot-platform/iot-platform-ui/src/lib/ui/components/progress-bar-button/progress-bar-button.module';

import { AuthValidators } from '../../validators/auth.validator';
import { LoginPasswordRulesComponent } from '../login-password-rules/login-password-rules.component';

@Component({
  standalone: true,
  imports: [
    FlexLayoutModule,
    TranslateModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatIconModule,
    MatInput,
    MatButtonModule,
    LoginPasswordRulesComponent,
    ProgressBarButtonModule
  ],
  selector: 'iot-platform-login-change-temporary-password-form',
  templateUrl: './login-change-temporary-password-form.component.html',
  styleUrls: ['./login-change-temporary-password-form.component.scss', '../../scss/style.scss']
})
export class LoginChangeTemporaryPasswordFormComponent {
  hasError = input<{ message: string }>();
  cancel = output();
  changeTemporaryPassword = output<string>();

  form: UntypedFormGroup;

  enableGauge: WritableSignal<boolean> = signal(false);
  password: WritableSignal<string | null> = signal(null);

  constructor() {
    this.form = new UntypedFormGroup({
      newPassword: new UntypedFormControl(null, [Validators.required, AuthValidators.checkPassword]),
      confirmNewPassword: new UntypedFormControl(null, [Validators.required, this.checkConfirmPassword.bind(this)])
    });
  }

  get newPassword(): AbstractControl {
    return this.form.get('newPassword');
  }

  get confirmNewPassword(): AbstractControl {
    return this.form.get('confirmNewPassword');
  }

  sendPasswordToRules(value: string): void {
    this.password.set(value);
  }

  checkConfirmPassword(confirmPassword: UntypedFormControl | AbstractControl) {
    if (!this.form) {
      return null;
    }
    const password: AbstractControl = this.form.controls.newPassword;
    return confirmPassword.value === password.value ? null : { different: true };
  }

  onCancel() {
    this.cancel.emit();
  }

  onChangeTemporaryPassword() {
    this.changeTemporaryPassword.emit(this.newPassword.value);
  }
}
