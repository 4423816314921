import { DataGuardCheck, DataGuardDetailedChecksApiResponse, DataGuardEvent } from '@iot-platform/models/data-guard';
import { Observable } from 'rxjs';

export abstract class AbstractDataGuardService {
  abstract getDataGuardOverviewChecks(): Observable<DataGuardCheck[]>;

  abstract getDataGuardChecksByElementId(): Observable<DataGuardDetailedChecksApiResponse>;

  abstract closeButtonClick(): void;

  abstract dispatchDataGuardEvent(event: DataGuardEvent): void;

  // abstract getElementById(elementId: string): Observable<CommonGenericModel>;
  // abstract getElementOverviewCard(elementChecks: T, element: CommonGenericModel): OverviewCard;
}
