import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatCardModule } from '@angular/material/card';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { CardLoaderModule, DetailPopupModule } from '@iot-platform/iot-platform-ui';
import { BaseUser, BusinessProfile, Entity, UserAccount } from '@iot-platform/models/common';
import { TranslateModule } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { UserInfoPopupService } from '../../services/user-info-popup.service';

@Component({
  standalone: true,
  imports: [AsyncPipe, DetailPopupModule, FlexLayoutModule, TranslateModule, MatIconModule, MatCardModule, CardLoaderModule],
  selector: 'iot-platform-users-user-info-popup',
  templateUrl: './user-info-popup.component.html',
  styleUrls: ['./user-info-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserInfoPopupComponent {
  private readonly userInfoPopupService: UserInfoPopupService = inject(UserInfoPopupService);
  public readonly dialogRef: MatDialogRef<UserInfoPopupComponent> = inject(MatDialogRef<UserInfoPopupComponent>);
  public data: { user: BaseUser } = inject(MAT_DIALOG_DATA);

  userAccount$: Observable<UserAccount> = this.userInfoPopupService.getUserAccountById(this.data.user.id);
  businessProfiles$: Observable<BusinessProfile[]> = this.userInfoPopupService.getBusinessProfilesByUserId(this.data.user.id);

  getFormattedEntities(entities: Entity[]): string {
    return entities?.map((entity) => entity.name).join(', ');
  }
}
