import { NgModule } from '@angular/core';
import { DateFormatPipe } from '@iot-platform/pipes';
import { TranslateService } from '@ngx-translate/core';
import { HighchartsChartModule } from 'highcharts-angular';
import { IconModule } from '../components/icon/icon.module';
import { SharedModule } from '../shared.module';
import { VariableChartDialogComponent } from './variable-chart-dialog/variable-chart-dialog.component';
import { VariableChartComponent } from './variable-chart.component';
import { VariableChartService } from './variable-chart.service';
import { VariableItemComponent } from './variable-item/variable-item.component';

@NgModule({
  imports: [SharedModule, IconModule, HighchartsChartModule, DateFormatPipe],
  declarations: [VariableChartComponent, VariableItemComponent, VariableChartDialogComponent],
  exports: [VariableChartComponent, VariableChartDialogComponent],
  providers: [TranslateService, VariableChartService, DateFormatPipe]
})
export class VariableChartModule {}
