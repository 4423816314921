import { ColDef, ValueGetterParams } from '@ag-grid-community/core';
import { HttpClient } from '@angular/common/http';
import { ComponentRef, EventEmitter, Inject, Injectable, ViewContainerRef } from '@angular/core';
import { ApiHelpers } from '@iot-platform/iot-platform-utils';
import {
  CommonApiListResponse,
  CommonApiRequest,
  CommonApiResponse,
  CommonGenericModel,
  CommonPagination,
  CustomExportWrapper,
  Environment,
  Filter,
  Pagination,
  PlatformRequest,
  PlatformResponse
} from '@iot-platform/models/common';
import { ExportParams, ExportType, I4BGrid, I4BGridData, I4BGridOptions } from '@iot-platform/models/grid-engine';
import { chunk, cloneDeep, get, orderBy } from 'lodash';
import * as moment from 'moment';
import { BehaviorSubject, EMPTY, forkJoin, Observable, Subject } from 'rxjs';
import { catchError, concatMap, map, retry, share, takeUntil } from 'rxjs/operators';
import { GridsService } from '../../services/grids.service';
import { GridExportComponent } from './grid-export.component';

type ResponseData = PlatformResponse | CommonApiResponse<CommonGenericModel, CommonPagination>;

/* eslint-disable  @typescript-eslint/no-explicit-any */

/* eslint-disable no-underscore-dangle */
@Injectable({
  providedIn: 'root'
})
export class GridExportService {
  private pagination: Pagination;
  private viewRef: ViewContainerRef;
  private grid: I4BGrid<I4BGridOptions, I4BGridData>;
  private gridMeta: any;
  private rowData: any[] = [];
  private columnsDef: ColDef[] = [];
  private filters: Filter[] = [];
  private fileName: string;
  private cancel$: Subject<void>;
  private complete$: Subject<void>;
  private readonly loadData$: Subject<number> = new Subject<number>();
  private chunksValue: number;
  private componentRef: ComponentRef<GridExportComponent>;
  private exportWrapper: CustomExportWrapper<any>;
  private polling: boolean;
  private readonly _progressBarValue$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  private readonly _loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private readonly excludedColumnIds: string[] = ['selection', 'delay', 'button'];
  onExport$: EventEmitter<ExportParams> = new EventEmitter<ExportParams>();
  onExportComplete$: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    @Inject('environment') private readonly environment: Environment,
    private readonly http: HttpClient,
    private readonly gridsService: GridsService
  ) {}

  private _currentType: ExportType;

  get currentType(): ExportType {
    return this._currentType;
  }

  set currentType(type: ExportType) {
    this._currentType = type;
  }

  get progressBarValue$(): Observable<number> {
    return this._progressBarValue$.asObservable();
  }

  get loading$(): Observable<boolean> {
    return this._loading$.asObservable();
  }

  private get defaultFileName(): string {
    return moment().format('YYYY-MM-DD');
  }

  setViewRef(viewRef: ViewContainerRef): void {
    this.viewRef = viewRef;
  }

  setGrid(grid: I4BGrid<I4BGridOptions, I4BGridData>): void {
    this.grid = grid;
    const {
      data: {
        response: { pagination, data }
      },
      columns
    } = grid;
    this.setPagination(pagination as Pagination);
    this.setRowData([...data]);
    this.setGridMeta({
      masterViewTable: {
        bluePrint: {
          columns
        }
      }
    });
  }

  setPagination(pagination: Pagination): void {
    this.pagination = {
      ...pagination
    };
  }

  setColumnsDef(columns: ColDef[]): void {
    this.columnsDef = cloneDeep([...columns.filter((c: ColDef) => !this.excludedColumnIds.includes(c.field))]);
  }

  setGridMeta(gridMeta: any): void {
    this.gridMeta = cloneDeep(gridMeta);
    this.setColumnsDef(this.generateColumnDefs());
  }

  setRowData(rowData: any[]): void {
    this.rowData = cloneDeep(rowData);
  }

  setParams(params: ExportParams): void {
    this.filters = get(params, 'filters', []);
    this.fileName = get(params, 'fileName', this.defaultFileName);
    this.chunksValue = get(params, 'parallelRequests', 1);
    this.currentType = get(params, 'type', ExportType.XLSX);
    this.exportWrapper = get(params, 'customExportWrapper', null);
    this.polling = get(params, 'polling', true);
  }

  export(params: ExportParams): void {
    this.setParams(params);
    this.onExport$.emit({
      ...params
    });
  }

  loadData(fileName: string): void {
    this.cancel$ = new Subject<void>();
    this.complete$ = new Subject<void>();
    this.fileName = fileName;
    const doPolling: boolean = this.rowData.length < this.pagination.total;
    if (this.exportWrapper) {
      this.applyCustomWrapper();
    } else if (this.polling && doPolling) {
      let request: Partial<PlatformRequest | CommonApiRequest> = { filters: this.filters, page: 0, limit: 1000 };
      if (this.grid) {
        request = {
          ...request,
          concept: this.grid.masterview.toLowerCase(),
          variables: this.grid.gridOptions.variableNames,
          tags: this.grid.gridOptions.tagIds
        };
      }
      this.pollingHttpRequests(request);
    } else {
      this.exportData();
    }
  }

  cancel(): void {
    this.init();
    this.unsubscribe(this.cancel$);
  }

  destroy(): void {
    this.cancel();
    this.clear();
    if (this.componentRef) {
      this.componentRef.destroy();
      this.componentRef = null;
    }
    this.unsubscribe(this.complete$);
  }

  // Used for old grid engines and mat tables
  private generateColumnDefs(): ColDef[] {
    return this.gridMeta.masterViewTable.bluePrint.columns.map((col) => {
      const gridRow: ColDef = {
        field: col.id,
        headerName: col.name,
        sortable: col.sortable,
        filter: true,
        resizable: true,
        width: col.cellWidth ? parseInt(col.cellWidth) : undefined,
        suppressHeaderMenuButton: false,
        headerComponent: col.headerType,
        headerComponentParams: { headerIcon: col.headerIcon ?? '', headerTooltip: col.headerTooltip ?? '' },
        cellRenderer: col.cellType,
        cellRendererParams: {
          eventConfiguration: { type: col.clickEvent?.type ?? '', options: col.clickEvent?.options ?? '' },
          cellOptions: col.cellOptions ? col.cellOptions : col.cellTypeOptions ?? ''
        }
      };
      if (col.valueGetter) {
        gridRow.valueGetter = (params: ValueGetterParams) => get(params.data, col.valueGetter);
      }
      return gridRow;
    });
  }

  private unsubscribe(subject: Subject<void>): void {
    if (subject) {
      subject.next();
      subject.complete();
    }
  }

  private init(): void {
    this.onExportComplete$.emit(false);
    this._loading$.next(false);
    this._progressBarValue$.next(0);
  }

  private clear(): void {
    this.rowData = [];
    this.columnsDef = [];
    this.filters = [];
    this.gridMeta = {};
    this.grid = null;
    this.fileName = null;
    this.exportWrapper = null;
  }

  private applyCustomWrapper(): void {
    this._loading$.next(true);
    this.exportWrapper
      .loadData()
      .pipe(takeUntil(this.cancel$))
      .subscribe((data: any[]) => {
        this._loading$.next(false);
        this.rowData = [...data];
        this.exportData();
        this.cancel();
      });
  }

  private transformData(): any[] {
    return this.exportWrapper ? this.exportWrapper.transform([...this.rowData]) : [...this.rowData];
  }

  private exportData(): void {
    this.componentRef = null;
    this.componentRef = this.createComponent();
    this.componentRef.instance.ref.detach();
    this.componentRef.instance.columnDefs = this.columnsDef.map(this.insertUnitCol);
    this.componentRef.instance.rowData = this.transformData();
    this.componentRef.instance.gridMeta = this.gridMeta;
    this.componentRef.instance.fileName = this.fileName;
    this.componentRef.instance.gridReady.pipe(takeUntil(this.complete$)).subscribe(() => {
      this.componentRef.instance.exportData(this.currentType);
      this.onExportComplete$.emit(true);
    });
    this.componentRef.instance.ref.detectChanges();
  }

  private insertUnitCol(groupOrCol: ColDef): ColDef {
    // for CC1 (EVENTS mv)
    if (groupOrCol.field === 'context.deviceVariable' || groupOrCol.field === 'context.assetVariable') {
      groupOrCol.cellRendererParams.cellOptions = { ...groupOrCol.cellRendererParams.cellOptions, displayUnit: false };
      groupOrCol['children'] = [{ ...groupOrCol }];
      groupOrCol['children'].push({
        field: groupOrCol.field,
        headerName: 'UNIT',
        cellRenderer: (params) => params.value?.unit
      });
      groupOrCol.headerName = '';
    }
    // for CC2 (referential mv)
    if (groupOrCol.hasOwnProperty('children')) {
      const variableCol = groupOrCol['children'].find(
        (d) =>
          d.field === `followedVariables.${groupOrCol.field}` ||
          d.field === `expandedVariables.${groupOrCol.field}` ||
          d.field === `context.${groupOrCol.field}`
      );
      if (variableCol) {
        variableCol.cellRendererParams.cellOptions = {
          ...variableCol.cellRendererParams.cellOptions,
          displayUnit: false
        };
        groupOrCol['children'].push({
          field: variableCol.field,
          headerName: 'UNIT',
          cellRenderer: (params) => params.value?.unit
        });
      }

      // special treatment for diagnostic variables
      const diagnosticVariableCol = groupOrCol['children'].find((c) => c.cellRenderer === 'diagnosticVariable');
      if (diagnosticVariableCol) {
        groupOrCol['children'].push({
          field: 'expandedVariable',
          headerName: 'UNIT',
          cellRenderer: 'diagnosticVariable',
          cellRendererParams: {
            ...diagnosticVariableCol.cellRendererParams,
            cellOptions: { ...diagnosticVariableCol.cellRendererParams.cellOptions }
          }
        });
      }
    }
    return groupOrCol;
  }

  private getStream(stream: Observable<ResponseData> | Observable<ResponseData>[]): Observable<ResponseData> {
    return forkJoin(stream instanceof Array ? stream : [stream]).pipe(
      map((result: ResponseData[]) => {
        const data = result.reduce((acc: any[], response: ResponseData) => acc.concat(response.data), []);
        return { ...result.pop(), data };
      }),
      takeUntil(this.cancel$)
    );
  }

  private getChunkedStreams(size: number, request: PlatformRequest | CommonApiRequest): Observable<ResponseData>[][] {
    const array: {
      page: number;
      stream: Observable<ResponseData>;
    }[] = Array.from(Array.from({ length: size }), (_, page: number) => ({
      page,
      stream: this.grid
        ? this.gridsService
            .loadGridData({
              ...request,
              page
            })
            .pipe(retry(1))
        : this.callHttp({ ...request, page })
    }));
    return chunk(
      orderBy(array, ({ page }) => page, 'asc').map((e) => e.stream),
      this.chunksValue
    );
  }

  private pollingHttpRequests(request: PlatformRequest | CommonApiRequest): void {
    const size: number = Math.ceil(this.pagination.total / request.limit);
    const chunks: Observable<ResponseData>[][] = this.getChunkedStreams(size, request);
    let currentPage = 0;
    const totalPages: number = chunks.length;
    let dataToExport = [];
    this._loading$.next(true);
    this.loadData$
      .pipe(
        concatMap((i: number) => this.getStream(chunks[i])),
        share(),
        catchError(() => {
          this.cancel();
          return EMPTY;
        }),
        takeUntil(this.cancel$)
      )
      .subscribe((response: ResponseData) => {
        currentPage++;
        this.updateProgressBarValue(currentPage, totalPages);
        dataToExport = [...dataToExport, ...response.data];
        if (currentPage < totalPages) {
          this.loadData$.next(currentPage);
        } else {
          this.rowData = [...dataToExport];
          this.exportData();
          this.cancel();
        }
      });
    this.loadData$.next(0);
  }

  private createComponent(): ComponentRef<GridExportComponent> {
    this.viewRef.clear();
    return this.viewRef.createComponent(GridExportComponent);
  }

  private callHttp(request: PlatformRequest): Observable<PlatformResponse> {
    return this.http
      .get<CommonApiListResponse<any>>(`${this.environment.api.url}${this.gridMeta.metadata.url}`, { params: ApiHelpers.getHttpParams(request) })
      .pipe(
        map((data: CommonApiListResponse<any>) => ({
          data: data.content,
          currentPage: data.page.curPage,
          hasMore: data.page.hasMore,
          limit: data.page.limit,
          maxPage: data.page.maxPage,
          total: data.page.total
        })),
        retry(1)
      );
  }

  private updateProgressBarValue(currentPage, totalPage): void {
    this._progressBarValue$.next((currentPage * 100) / totalPage);
  }
}
