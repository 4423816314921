import { AsyncPipe, UpperCasePipe } from '@angular/common';
import { Component, DestroyRef, effect, Inject, inject, Injector, OnInit, Signal, signal, WritableSignal } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AbstractControl, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatToolbarModule } from '@angular/material/toolbar';
import { AsyncAutocompleteModule } from '@iot-platform/iot-platform-ui';
import { NameValidators } from '@iot-platform/iot-platform-utils';
import { Entity } from '@iot-platform/models/common';
import { PoEventAlgorithm, PoEventRule } from '@iot-platform/models/i4b';
import { EntitiesService } from '@iot-platform/shared';
import { PoEventsService } from '@iot-platform/shared/services';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

@Component({
  standalone: true,
  imports: [
    AsyncPipe,
    MatIconModule,
    FlexLayoutModule,
    TranslateModule,
    AsyncAutocompleteModule,
    ReactiveFormsModule,
    MatToolbarModule,
    UpperCasePipe,
    MatCardModule,
    MatSelectModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule
  ],
  selector: 'iot4bos-ui-po-event-create-form',
  templateUrl: './po-event-create-form.component.html'
})
export class PoEventCreateFormComponent implements OnInit {
  @Inject(MAT_DIALOG_DATA) public data: { rule: PoEventRule } = inject(MAT_DIALOG_DATA);
  public dialogRef: MatDialogRef<PoEventCreateFormComponent> = inject(MatDialogRef);
  form: UntypedFormGroup = new UntypedFormGroup({});
  selectedAlgo: WritableSignal<PoEventAlgorithm | null> = signal(null);
  private readonly injector: Injector = inject(Injector);
  private readonly destroyRef: DestroyRef = inject(DestroyRef);
  private readonly translateService: TranslateService = inject(TranslateService);
  private readonly poEventsService: PoEventsService = inject(PoEventsService);
  algos: Signal<PoEventAlgorithm[] | undefined> = toSignal(this.poEventsService.getPoEventAlgorithms().pipe(takeUntilDestroyed(this.destroyRef)));
  private readonly entitiesService: EntitiesService = inject(EntitiesService);
  entities: Signal<Entity[] | undefined> = toSignal(this.entitiesService.getHierarchicallySortedEntities().pipe(takeUntilDestroyed(this.destroyRef)));

  get name(): AbstractControl {
    return this.form.get('name') as AbstractControl;
  }

  get entity(): AbstractControl {
    return this.form.get('entity') as AbstractControl;
  }

  get type(): AbstractControl {
    return this.form.get('type') as AbstractControl;
  }

  get concept(): AbstractControl {
    return this.form.get('concept') as AbstractControl;
  }

  get title$(): Observable<string> {
    return this.data.rule
      ? this.translateService.get('PO_EVENTS.CREATION_CONFIGURATION_POPUP.EDIT_TITLE', { ruleName: this.data.rule?.name })
      : this.translateService.get('PO_EVENTS.CREATION_CONFIGURATION_POPUP.CREATE_TITLE');
  }

  ngOnInit() {
    this.loadAlgosEffect();
    this.initForm();
  }

  onEntitySelection(entity: Entity): void {
    this.entity.setValue(entity);
    this.name.updateValueAndValidity({ onlySelf: true, emitEvent: true });
  }

  onResetEntity(): void {
    this.entity.reset();
    this.name.updateValueAndValidity({ onlySelf: true, emitEvent: true });
  }

  onAlgoSelectionChange(algo: string): void {
    this.selectedAlgo.set(this.algos()?.find((elem) => elem.name === algo) as PoEventAlgorithm);
    this.concept.enable();
  }

  isButtonDisabled(): boolean {
    return this.form.invalid || this.form.pending || this.name.getRawValue() === this.data.rule?.name;
  }

  close(): void {
    this.dialogRef.close();
  }

  save(action: string): void {
    if (this.data.rule) {
      this.dialogRef.close({
        action: 'EDIT',
        rule: {
          ...this.data.rule,
          name: this.name.getRawValue().trim()
        }
      });
    } else {
      this.dialogRef.close({
        action,
        rule: {
          name: this.name.getRawValue().trim(),
          entity: this.entity.getRawValue(),
          algo: this.type.getRawValue(),
          concept: this.concept.getRawValue()
        }
      });
    }
  }

  private loadAlgosEffect(): void {
    effect(
      () => {
        const algos = this.algos();
        if (this.data.rule) {
          this.selectedAlgo.set(algos?.find((elem) => elem.name === this.data.rule?.algo) as PoEventAlgorithm);
        }
      },
      { injector: this.injector, allowSignalWrites: true }
    );
  }

  private initForm(): void {
    this.form = new UntypedFormGroup({
      name: new UntypedFormControl(this.data.rule?.name?.trim() ?? '', {
        validators: [Validators.required, Validators.maxLength(50), Validators.pattern('\\S.*')],
        asyncValidators: [NameValidators.asyncUniqueNameValidatorByEntity(this.poEventsService, this.data.rule?.name ?? '')]
      }),
      entity: new UntypedFormControl(
        {
          value: this.data.rule?.entity ?? '',
          disabled: this.data.rule
        },
        [Validators.required]
      ),
      type: new UntypedFormControl(
        {
          value: this.data.rule?.algo ?? '',
          disabled: this.data.rule
        },
        [Validators.required]
      ),
      concept: new UntypedFormControl({ value: this.data.rule?.concept ?? '', disabled: true }, [Validators.required])
    });
  }
}
