import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PopupComponent } from '@iot-platform/iot-platform-ui';
import { SortUtil } from '@iot-platform/iot-platform-utils';
import { Environment, UserAccount, UserFavoriteFilters } from '@iot-platform/models/common';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserPreferencesService {
  openPreferences$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  constructor(
    @Inject('environment') private readonly environment: Environment,
    private readonly http: HttpClient,
    private readonly dialog: MatDialog
  ) {}

  static getFavoriteFiltersWithOrder(favoriteFilters: UserFavoriteFilters): UserFavoriteFilters {
    const masterViews = Object.keys(favoriteFilters);

    return masterViews.reduce((acc: UserFavoriteFilters, masterView: string) => {
      const filterConcepts = Object.keys(favoriteFilters[masterView]);

      // format favorite filters with temporary order
      acc[masterView] = filterConcepts.reduce(
        (
          filtersByConcept: {
            [filterConcept: string]: Array<{ name: string; order: number }>;
          },
          filterConcept: string
        ) => {
          filtersByConcept[filterConcept] = favoriteFilters[masterView][filterConcept].map((filter) => {
            if (typeof filter === 'string') {
              filter = { name: filter, order: 1000 };
            }
            return filter;
          });

          return filtersByConcept;
        },
        {}
      );

      // update favorite filters temporary order
      acc[masterView] = filterConcepts.reduce(
        (
          filtersByConcept: {
            [filterConcept: string]: Array<{ name: string; order: number }>;
          },
          filterConcept: string
        ) => {
          filtersByConcept[filterConcept] = acc[masterView][filterConcept].map((filter) => {
            if (filter.order === 1000) {
              filter.order = UserPreferencesService.getFilterOrderByDefault(filter.name, acc[masterView]);
            }
            return filter;
          });

          return filtersByConcept;
        },
        {}
      );

      return acc;
    }, {});
  }

  static getFilterOrderByDefault(
    filterName: string,
    filtersByMasterView: {
      [filterConcept: string]: Array<{ name: string; order: number }>;
    }
  ): number {
    const favoriteFiltersFlatten: Array<{
      name: string;
      order: number;
    }> = Object.values(filtersByMasterView).flat().sort(SortUtil.sortByOrderThenName);

    favoriteFiltersFlatten.reduce(
      (
        acc: Array<{ name: string; order: number }>,
        value: {
          name: string;
          order: number;
        },
        index: number
      ) => {
        if (value.order === 1000) {
          value.order = index;
        }
        acc.push(value);
        return acc;
      },
      []
    );

    return favoriteFiltersFlatten.find((filter) => filter.name === filterName).order;
  }

  public loadPreferences(): Observable<UserAccount> {
    return this.http.get(`${this.environment.api.url}/account`).pipe(
      map((user: UserAccount) => ({
        ...user,
        preferences: user.preferences
          ? {
              ...user.preferences,
              favoriteFilters: UserPreferencesService.getFavoriteFiltersWithOrder(user.preferences.favoriteFilters ?? {})
            }
          : {}
      }))
    );
  }

  public saveUserPreferences(user): Observable<UserAccount> {
    return this.http.put<UserAccount>(`${this.environment.api.url}/account`, user);
  }

  public loadMySettings(settingName: string) {
    const settings$ = this.http.get(`${this.environment.api.url}/settings/me/subs/${settingName}`);
    const version$ = this.loadSettingsVersion();
    const comb$ = combineLatest([settings$, version$]);

    return comb$.pipe(
      switchMap(([settings, version]) => {
        if (settings && settings['metadata']?.version === version) {
          return of(settings);
        } else {
          if (settings !== null) {
            return this.dialog
              .open(PopupComponent, {
                width: '500px',
                disableClose: true,
                data: {
                  type: 'confirm',
                  value: 'Your settings are for a deprecated version. We need to override with default values'
                }
              })
              .afterClosed()
              .pipe(
                switchMap((value) => {
                  if (value) {
                    return this.resetAllMySettings().pipe(switchMap((_) => this.loadSpecificDefaultSettings(settingName)));
                  } else {
                    return of(settings);
                  }
                }),
                catchError(() => this.loadSpecificDefaultSettings(settingName))
              );
          } else {
            return this.loadSpecificDefaultSettings(settingName);
          }
        }
      }),
      catchError(() => this.loadSpecificDefaultSettings(settingName))
    );
  }

  public loadActiveSettings(settingName: string) {
    return this.loadMySettings(settingName).pipe(
      map((setting: any) => {
        if (setting) {
          const returnedSettings = {
            ...setting,
            masterViewTable: {
              ...setting.masterViewTable,
              bluePrint: {
                ...setting.masterViewTable.bluePrint,
                columns: setting.masterViewTable.bluePrint.columns.filter((c) => c.default === true)
              }
            }
          };
          return returnedSettings;
        }
      })
    );
  }

  public loadAllDefaultSettings() {
    return this.http.get(`${this.environment.api.url}/settings/default`);
  }

  public loadSettingsVersion() {
    return this.http.get('assets/data/build.json').pipe(
      map((build) => {
        const version: string = build['version'].split('-')[1] as string;
        return version;
      })
    );
  }

  public loadSpecificDefaultSettings(settingName: string) {
    return this.http.get(`${this.environment.api.url}/settings/default/subs/${settingName}`);
  }

  public loadSpecificDefaultSettingsWithDefaultColumnsOnly(settingName: string) {
    return this.http.get(`${this.environment.api.url}/settings/default/subs/${settingName}`).pipe(
      map((settings: any) => ({
        ...settings,
        masterViewTable: {
          ...settings.masterViewTable,
          bluePrint: {
            ...settings.masterViewTable.bluePrint,
            columns: settings.masterViewTable.bluePrint.columns.filter((c) => c.default === true)
          }
        }
      }))
    );
  }

  public loadDefaultMVPrefs() {
    return this.http.get(`${this.environment.api.url}/settings/default`);
  }

  public resetAllMySettings() {
    return this.http.put(`${this.environment.api.url}/settings/me/`, null);
  }

  public saveMySettings(settingName: string, settings) {
    return this.loadSettingsVersion().pipe(
      switchMap((version) => {
        const versioned = {
          ...settings,
          metadata: {
            ...settings.metadata,
            version
          }
        };
        return this.http.put(`${this.environment.api.url}/settings/me/subs/${settingName}`, versioned);
      })
    );
  }

  public saveMySettingsAndGetOnlyActive(settingName: string, settings) {
    return this.saveMySettings(settingName, settings).pipe(
      map((setting: any) => {
        const returnedSettings = {
          ...setting,
          masterViewTable: {
            ...setting.masterViewTable,
            bluePrint: {
              ...setting.masterViewTable.bluePrint,
              columns: setting.masterViewTable.bluePrint.columns.filter((c) => c.default === true)
            }
          }
        };
        return returnedSettings;
      })
    );
  }

  public saveAllMySettings(allMySettings) {
    return this.http.put(`${this.environment.api.url}/settings/me`, allMySettings);
  }

  public resetAllDefaultSettings() {
    return this.http.put(`${this.environment.api.url}/settings/default`, null);
  }

  public saveAllDefaultSettings(defaults) {
    return this.http.put(`${this.environment.api.url}/settings/default`, defaults);
  }

  public saveDefaultSettings(specificSettingName: string, defaults) {
    return this.http.put(`${this.environment.api.url}/settings/default/subs/${specificSettingName}`, defaults);
  }

  closePreferences(): void {
    this.openPreferences$.next(false);
  }
}
