import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
// tslint:disable-next-line:max-line-length
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DateFormatPipe, InfoDisplayPipe, NumberFormatPipe, UserNamePipe } from '@iot-platform/pipes';
import { IconModule } from '@iot-platform/shared/components';
import { TranslateModule } from '@ngx-translate/core';
import { EventSeverityDisplayModule } from 'libs/iot-platform/iot-platform-ui/src/lib/ui/components/event-severity-display/event-severity-display.module';
import { MasterViewEngineDirectiveModule } from '../../master-view-engine/master-view-table/master-view-engine-directive.module';
import { ActiveEventsCellComponent } from './active-events-cell/active-events-cell.component';
import { ArrayOfObjectsCellComponent } from './array-of-objects-cell/array-of-objects-cell.component';
import { AssetVariableCellComponent } from './asset-variable-cell/asset-variable-cell.component';
import { AssetVariableThresholdCellComponent } from './asset-variable-threshold-cell/asset-variable-threshold-cell.component';
import { BasicCellComponent } from './basic-cell/basic-cell.component';
import { BasicLinkCellComponent } from './basic-link-cell/basic-link-cell.component';
import { BooleanCellComponent } from './boolean-cell/boolean-cell.component';
import { ButtonCellComponent } from './button-cell/button-cell.component';
import { CallToActionCellComponent } from './call-to-action-cell/call-to-action-cell.component';
import { CallToActionMenuItemComponent } from './call-to-action-menu-item/call-to-action-menu-item.component';
import { ColorCellComponent } from './color-cell/color-cell.component';
import { CommentCellComponent } from './comment-cell/comment-cell.component';
import { CountCellComponent } from './count-cell/count-cell.component';
import { CyclicalCallFaultCellComponent } from './cyclical-call-fault-cell/cyclical-call-fault-cell.component';
import { DateCellComponent } from './date-cell/date-cell.component';
import { DynamicDataComponent } from './dynamic-data/dynamic-data.component';
import { DynamicIconCellComponent } from './dynamic-icon-cell/dynamic-icon-cell.component';
import { FollowedNumberCellComponent } from './followed-number-cell/followed-number-cell.component';
import { GridNameCellComponent } from './grid-name-cell/grid-name-cell.component';
import { IconCellComponent } from './icon-cell/icon-cell.component';
import { IconLinkCellComponent } from './icon-link-cell/icon-link-cell.component';
import { LastCommandStatusCellComponent } from './last-command-status-cell/last-command-status-cell.component';
import { LinkedCellComponent } from './linked-cell/linked-cell.component';
import { MasterViewCellContainerComponent } from './master-view-cell-container.component';
import { NumberCellComponent } from './number-cell/number-cell.component';
import { ProductCellComponent } from './product-cell/product-cell.component';
import { RatingCellModule } from './rating-cell/rating-cell.module';
import { RichVariableUnitCellComponent } from './rich-variable-unit-cell/rich-variable-unit-cell.component';
import { RichVariableValueCellComponent } from './rich-variable-value-cell/rich-variable-value-cell.component';
import { SeverityCellComponent } from './severity-cell/severity-cell.component';
import { ThresholdIconCellComponent } from './threshold-icon-cell/threshold-icon-cell.component';
import { TopicSelfSubscriptionCellComponent } from './topic-self-subscription-cell/topic-self-subscription-cell.component';
import { TranslatedCellComponent } from './translated-cell/translated-cell.component';
import { UserCellComponent } from './user-cell/user-cell.component';
import { ValueToIconCellComponent } from './value-to-icon-cell/value-to-icon-cell.component';
import { VariableDispatchCellComponent } from './variable-dispatch-cell/variable-dispatch-cell.component';
import { VariableValueCellComponent } from './variable-value-cell/variable-value-cell.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    MasterViewEngineDirectiveModule,
    DateFormatPipe,
    InfoDisplayPipe,
    MatTooltipModule,
    IconModule,
    FlexModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    FormsModule,
    MatRadioModule,
    MatMenuModule,
    MatButtonModule,
    MatIconModule,
    MatChipsModule,
    EventSeverityDisplayModule,
    RatingCellModule,

    ButtonCellComponent,
    NumberFormatPipe,
    UserNamePipe
  ],
  declarations: [
    MasterViewCellContainerComponent,
    BasicCellComponent,
    DateCellComponent,
    IconCellComponent,
    CommentCellComponent,
    BasicLinkCellComponent,
    IconLinkCellComponent,
    ColorCellComponent,
    DynamicDataComponent,
    BooleanCellComponent,
    TranslatedCellComponent,
    CallToActionCellComponent,
    CallToActionMenuItemComponent,
    NumberCellComponent,
    CountCellComponent,
    VariableValueCellComponent,
    CyclicalCallFaultCellComponent,
    UserCellComponent,
    ProductCellComponent,
    TopicSelfSubscriptionCellComponent,
    ArrayOfObjectsCellComponent,
    SeverityCellComponent,
    RichVariableValueCellComponent,
    RichVariableUnitCellComponent,
    DynamicIconCellComponent,
    LinkedCellComponent,
    FollowedNumberCellComponent,
    ActiveEventsCellComponent,
    VariableDispatchCellComponent,
    ThresholdIconCellComponent,
    LastCommandStatusCellComponent,
    ValueToIconCellComponent,
    AssetVariableCellComponent,
    AssetVariableThresholdCellComponent,
    GridNameCellComponent
  ],
  exports: [MasterViewCellContainerComponent, CallToActionCellComponent, MasterViewCellContainerComponent, DateCellComponent, BasicCellComponent]
})
export class MasterViewCellModule {}
