@if (displaySearchBar) {
  <mat-sidenav-container (backdropClick)="closeNav()">
    <mat-sidenav-content class="variable-chart-side-nav-container" fxLayout="column" style="overflow-x: hidden">
      <div class="variable-chart-export-bar" fxLayout="row" fxLayoutAlign="space-between center">
        <div>
          <div class="variable-chart-days-filter" fxFlex fxLayout="row" fxLayoutAlign="space-between center">
            <button (click)="onSelectPeriod(1)" [class.selected]="chartPeriod === 1"
                    class="variable-chart-selector-button" mat-raised-button>
              1 {{ 'VARIABLE_CHART.DAY' | translate }}
            </button>
            <button (click)="onSelectPeriod(7)" [class.selected]="chartPeriod === 7"
                    class="variable-chart-selector-button" mat-raised-button>
              7 {{ 'VARIABLE_CHART.DAY' | translate }}s
            </button>
            <button (click)="onSelectPeriod(30)" [class.selected]="chartPeriod === 30"
                    class="variable-chart-selector-button" mat-raised-button>
              30 {{ 'VARIABLE_CHART.DAY' | translate }}s
            </button>
          </div>
          <div class="variable-chart-dates-filter" fxFlex fxLayout="row" fxLayoutAlign="space-between center">
            <div
              class="variable-chart-datepicker"
              fxLayout="row"
              style="width: 140px; background-color: #ececec; margin-left: 8px; margin-right: 8px; padding-left: 8px"
            >
              <input #startDate [matDatepicker]="startPicker" matInput
                     placeholder="{{ 'VARIABLE_CHART.FROM' | translate }}"/>
              <mat-datepicker-toggle [for]="startPicker" matTextSuffix></mat-datepicker-toggle>
              <mat-datepicker #startPicker></mat-datepicker>
            </div>
            <div class="variable-chart-datepicker" fxLayout="row"
                 style="width: 140px; background-color: #ececec; padding-left: 8px">
              <input #endDate [matDatepicker]="endPicker" matInput
                     placeholder="{{ 'VARIABLE_CHART.TO' | translate }}"/>
              <mat-datepicker-toggle [for]="endPicker" matTextSuffix></mat-datepicker-toggle>
              <mat-datepicker #endPicker></mat-datepicker>
            </div>
          </div>
          <button
            (click)="onSelectDates(startDate.value, endDate.value)"
            [class.selected]="chartPeriod === 0"
            [disabled]="!startDate.value"
            class="variable-chart-selector-button"
            fxFlex
            mat-raised-button
          >
            {{ 'VARIABLE_CHART.APPLY' | translate | uppercase }}
          </button>
        </div>
        <div fxLayout="row" fxLayoutAlign="start center">
          <mat-slide-toggle (change)="onAutoScaleChange($event)"
                            [checked]="(scale$ | async) === null">{{ 'VARIABLE_CHART.AUTO_SCALE' | translate }}
          </mat-slide-toggle>
          <button (click)="openVariables()" [matTooltip]="'VARIABLE_CHART.ADD_VARIABLES' | translate"
                  class="variable-chart-add-variables-button" mat-icon-button
                  matTooltipClass="regular-tooltip">
            <mat-icon>addchart</mat-icon>
          </button>
        </div>
      </div>
      @if (displayLoader) {
        <mat-progress-bar class="variable-chart-loader" color="accent"
                          mode="indeterminate"></mat-progress-bar>
      }
      <div [class.fullscreen]="fullscreenOn" class="variables-closed">
        <highcharts-chart
          #highChart
          [Highcharts]="Highcharts"
          [constructorType]="chartConstructorType"
          [oneToOne]="true"
          [options]="mainChartOptions$ | async"
          fxFlex
          fxLayout="column"
          style="width: 100%; height: 100%; display: block"
        ></highcharts-chart>
      </div>
    </mat-sidenav-content>
    <mat-sidenav #sidenavVariables (keydown.escape)="closeNav()" [opened]="variablesOpened" mode="over"
                 position="end">
      @if (displayAllVariablesLoader) {
        <div fxLayoutAlign="center center" style="width: 450px; height: 100%">
          <mat-spinner [diameter]="30" color="accent"></mat-spinner>
        </div>
      }
      @if (allVariables && !displayAllVariablesLoader) {
        <p fxLayout="column" style="width: 450px">
          <mat-form-field class="filter-input" color="accent">
            <mat-icon class="search-icon" matIconPrefix>search</mat-icon>
            <mat-label>{{ 'VARIABLE_CHART.FILTER_VARIABLES' | translate }}</mat-label>
            <input (keyup)="filterVariables($event, allVariables)" matInput type="text"/>
          </mat-form-field>
          <mat-list>
            @for (variable of filteredVariables; track variable) {
              <mat-list-item>
                <iot4bos-ui-variable-item
                  (toggle)="toggleVariable(variable, $event)"
                  [locked]="getLockedVariables(variable)"
                  [selected]="getSelectedVariables(variable)"
                  [variable]="variable"
                  fxFlex
                >
                </iot4bos-ui-variable-item>
              </mat-list-item>
            }
          </mat-list>
        </p>
      }
    </mat-sidenav>
  </mat-sidenav-container>
}
@if (!displaySearchBar) {
  <highcharts-chart
    #highChart
    [Highcharts]="Highcharts"
    [constructorType]="chartConstructorType"
    [oneToOne]="true"
    [options]="previewChartOptions$ | async"
    fxFlex
    style="width: 100%; height: 100%; display: block"
  ></highcharts-chart>
}
