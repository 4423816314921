<mat-card-content class="p-20 mve-detail-popup-container" fxLayout="column" fxLayoutGap="20px">
  <section fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
    <div fxFlex fxLayout="column" fxLayoutGap="6px">
      <span (click)="dialogRef.close(data.user)" class="mve-detail-popup-title fw-600 mve-detail-popup-title-clickable"
        >{{ data.user.firstname }} {{ data.user.lastname }}</span
      >
      <iot-platform-ui-detail-popup-display-property
        property="{{ 'IOT_DICTIONARY.ENTITIES' | translate }}:"
        value="{{ getFormattedEntities(data.user.entities) }}"
      ></iot-platform-ui-detail-popup-display-property>
      <div class="user-detail-popup-status">
        <span class="capitalize-first-letter">{{ 'IOT_DICTIONARY.STATUS' | translate }}:</span>
        <span [class]="'fw-600 capitalize-first-letter user-detail-popup-status-' + data.user.status.toLowerCase()" class="fw-600">{{
          'IOT_DICTIONARY.' + data.user.status.toLowerCase() | translate
        }}</span>
      </div>
    </div>
    <div fxFlex fxLayout="column" fxLayoutGap="6px">
      <iot-platform-ui-detail-popup-display-property
        property="{{ 'IOT_DICTIONARY.EMAIL' | translate }}:"
        value="{{ data.user.email }}"
      ></iot-platform-ui-detail-popup-display-property>
      <iot-platform-ui-detail-popup-display-property
        property="{{ 'ADMIN.USERS.TABLE.ACCOUNT_TYPE' | translate }}:"
        value="{{ (data.user.isShared ? 'ADMIN.USERS.TABLE.TYPE.SHARED_ACCOUNT' : 'ADMIN.USERS.TABLE.TYPE.USER_ACCOUNT') | translate }}"
      ></iot-platform-ui-detail-popup-display-property>
    </div>
  </section>

  <section fxLayout="column" fxLayoutGap="10px">
    <iot-platform-ui-detail-popup-section-header
      headerTitle="{{ (data.user.totalBusinessProfile <= 1 ? 'IOT_DICTIONARY.BUSINESS_PROFILE' : 'IOT_DICTIONARY.BUSINESS_PROFILES') | translate }} ({{
        data.user.totalBusinessProfile
      }})"
    ></iot-platform-ui-detail-popup-section-header>

    @if ({ userAccount: userAccount$ | async, businessProfiles: businessProfiles$ | async }; as userModel) {
      <div fxLayout="column" fxLayoutGap="10px" class="animate">
        <div class="user-detail-business-profile-list">
          <ul fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="20px">
            @for (profile of userModel.businessProfiles; track profile.id) {
              <li
                [ngClass]="{ 'out-of-scope-bp': profile.notCoveredByUserEntityAt !== null }"
                class="user-detail-business-profile-item"
                fxLayout="row"
                fxLayoutAlign="start center"
                fxLayoutGap="14px"
              >
                <mat-icon>people</mat-icon>
                <div fxLayout="column" fxLayoutAlign="start start">
                  <span>{{ profile.name }}</span>
                  @if (profile.notCoveredByUserEntityAt === null) {
                    <span class="grey"> {{ profile.entityName }} </span>
                  } @else {
                    <span class="out-of-scope-bp-message" color="warn">
                      {{ 'ADMIN.USERS.TABLE.BUSINESS_PROFILE_OUT_OF_SCOPE' | translate }}
                    </span>
                  }
                </div>
              </li>
            }
          </ul>
          @if (data.user.totalBusinessProfile === 0) {
            <span class="user-detail-no-bp-message" fxLayout="row" fxLayoutAlign="start center">
              {{ 'ADMIN.USERS.TABLE.NO_BUSINESS_PROFILE_ASSOCIATED' | translate }}
            </span>
          }
        </div>
        <iot-platform-ui-detail-popup-metadata-footer [data]="userModel.userAccount"></iot-platform-ui-detail-popup-metadata-footer>
      </div>
    }
  </section>
</mat-card-content>
