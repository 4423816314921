import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { NgStyle, UpperCasePipe } from '@angular/common';
import {
  Component,
  computed,
  DestroyRef,
  effect,
  inject,
  Injector,
  input,
  model,
  OnInit,
  output,
  Signal,
  signal,
  untracked,
  WritableSignal
} from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AbstractControl, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatChipInputEvent, MatChipsModule } from '@angular/material/chips';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MAT_SELECT_CONFIG, MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TagCategory, TagLabel } from '@iot-platform/models/common';
import { TranslateModule } from '@ngx-translate/core';
import { PopupComponent } from '../../popup/popup.component';
import { TagEditorFormService } from './tag-editor-form.service';
import { EditTagLabelDialogComponent } from '../edit-tag-label-dialog/edit-tag-label-dialog.component';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { NameValidators } from '@iot-platform/iot-platform-utils';

@Component({
  standalone: true,
  imports: [
    TranslateModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatTooltipModule,
    MatIconModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatChipsModule,
    UpperCasePipe,
    NgStyle
  ],
  selector: 'iot-platform-ui-tag-editor-form',
  templateUrl: './tag-editor-form.component.html',
  styleUrls: ['./tag-editor-form.component.scss'],
  providers: [
    {
      provide: MAT_SELECT_CONFIG,
      useValue: { overlayPanelClass: 'tag-color-select' }
    }
  ]
})
export class TagEditorFormComponent implements OnInit {
  private readonly dialog: MatDialog = inject(MatDialog);
  private readonly tagEditorFormService: TagEditorFormService = inject(TagEditorFormService);
  private readonly destroyRef: DestroyRef = inject(DestroyRef);
  protected readonly injector: Injector = inject(Injector);

  tagCategory = model<TagCategory>();
  colors = input<string[]>([]);
  mode = input<'ADD' | 'UPDATE'>();

  changeValue = output<void>();
  save = output<TagCategory>();
  remove = output<TagCategory>();

  tagNameMaxLength: Signal<number> = signal(20);
  maximumTagPerCategory: Signal<number> = signal(25);
  separatorKeysCodes: Signal<number[]> = signal([ENTER, COMMA]);

  form!: UntypedFormGroup;
  fomValueChanges!: Signal<any>;

  formValueChangesEffect = effect(
    () => {
      this.fomValueChanges();
      this.changeValue.emit();
    },
    { allowSignalWrites: true }
  );

  labelValue: WritableSignal<string | null> = signal(null);
  isLabelDuplicate: Signal<boolean> = computed(() => {
    const label = this.labelValue();
    const tagCategory = untracked(this.tagCategory);
    return !!tagCategory?.labels?.some((elem: TagLabel) => elem.name?.trim().toLowerCase() === label?.trim().toLowerCase());
  });

  colorsChangeEffect = effect(
    () => {
      const colors = this.colors();
      if (colors?.indexOf(this.color.getRawValue() as string) === -1) {
        this.color.setValue(null);
        this.color.updateValueAndValidity();
        this.color.markAsDirty();
      }
    },
    { allowSignalWrites: true }
  );

  get color(): AbstractControl {
    return this.form.get('color') as AbstractControl;
  }

  get name(): AbstractControl {
    return this.form.get('name') as AbstractControl;
  }

  get labels(): AbstractControl {
    return this.form.get('labels') as AbstractControl;
  }

  ngOnInit() {
    this.initForm();
    this.initFormValueChanges();
  }

  onAddLabel(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    const emptyTagLabelValue = !value;
    const duplicatedTagLabel = this.isLabelDuplicate();
    const maxTagLabelReached = this.tagCategory()?.labels?.length === this.maximumTagPerCategory();
    if (maxTagLabelReached || emptyTagLabelValue || duplicatedTagLabel) {
      return;
    }

    this.tagCategory.update((tagCategory) => ({
      ...tagCategory,
      labels: [...(tagCategory?.labels ?? []), { name: value.trim() }]
    }));

    event.chipInput.clear();
    this.labels.updateValueAndValidity();
    this.labels.markAsDirty();
  }

  onEditLabel(label: TagLabel, index: number): void {
    if (label.id) {
      this.dialog
        .open(EditTagLabelDialogComponent, {
          width: '350px',
          disableClose: true,
          data: {
            label,
            tagNameMaxLength: this.tagNameMaxLength(),
            categoryLabels: this.tagCategory()?.labels
          }
        })
        .afterClosed()
        .subscribe((newLabel: TagLabel) => {
          if (newLabel) {
            this.tagCategory.update((tagCategory) => ({ ...tagCategory, labels: tagCategory?.labels?.map((elem, i) => (i === index ? newLabel : elem)) }));
            this.labels.updateValueAndValidity();
            this.labels.markAsDirty();
          }
        });
    }
  }

  onRemoveLabel(index: number): void {
    this.tagCategory.update((tagCategory) => ({ ...tagCategory, labels: tagCategory?.labels?.filter((_, i) => i !== index) }));
    this.labels.updateValueAndValidity();
    this.labels.markAsDirty();
  }

  onRemoveTagCategory(): void {
    this.dialog
      .open(PopupComponent, {
        width: '500px',
        disableClose: true,
        data: { type: 'delete', value: this.tagCategory()?.name }
      })
      .afterClosed()
      .subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.remove.emit(this.tagCategory() as TagCategory);
        }
      });
  }

  onColorChange(color: string): void {
    this.tagCategory.update((tagCategory) => ({ ...tagCategory, color }));
  }

  onSave(): void {
    this.save.emit({
      ...this.tagCategory(),
      color: this.color.getRawValue(),
      name: this.name.getRawValue().trim()
    });

    if (this.mode() === 'ADD') {
      this.tagCategory.update((tagCategory) => ({ ...tagCategory, color: '', labels: [], name: '' }));
      this.form.clearValidators();
      this.form.reset();
    }
  }

  private initForm(): void {
    this.form = new UntypedFormGroup({
      color: new UntypedFormControl(this.tagCategory()?.color ? this.tagCategory()?.color : null, [Validators.required]),
      labels: new UntypedFormControl(this.tagCategory()?.labels ? this.tagCategory()?.labels : [], [
        Validators.required,
        Validators.maxLength(this.maximumTagPerCategory())
      ]),
      name: new UntypedFormControl(this.tagCategory()?.name ? this.tagCategory()?.name : null, {
        validators: [Validators.required, Validators.maxLength(this.tagNameMaxLength())],
        asyncValidators: [
          NameValidators.asyncUniqueNameValidator(this.tagEditorFormService, this.tagCategory()?.entityId as string, this.tagCategory()?.name ?? '')
        ]
      })
    });
  }

  private initFormValueChanges(): void {
    this.fomValueChanges = toSignal(this.form?.valueChanges?.pipe(takeUntilDestroyed(this.destroyRef)), { injector: this.injector });
  }
}
