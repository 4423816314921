import { NgStyle } from '@angular/common';
import { Component, effect, inject, Injector, input, output, signal, WritableSignal } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatChipsModule } from '@angular/material/chips';
import { MatIcon } from '@angular/material/icon';
import { Filter, TagCategory, TagLabel } from '@iot-platform/models/common';
import { DateFormatPipe } from '@iot-platform/pipes';
import { IconModule } from '@iot-platform/shared/components';
import { TranslateModule } from '@ngx-translate/core';

interface TagBody {
  tagCategory: TagCategory;
  tag: TagLabel;
}

@Component({
  standalone: true,
  imports: [MatIcon, MatChipsModule, IconModule, TranslateModule, DateFormatPipe, FlexLayoutModule, NgStyle],
  selector: 'iot-platform-ui-chip',
  templateUrl: './chip.component.html',
  styleUrls: ['./chip.component.scss']
})
export class ChipComponent {
  chip = input<TagBody | Filter | TagCategory>();
  isTag = input<boolean>();
  chipButton = input<{ display: boolean; name?: string }>();
  chipClick = output<any>();
  chipButtonClick = output<any>();
  category: WritableSignal<string | null> = signal(null);
  label: WritableSignal<string | null> = signal(null);
  dateLabel: WritableSignal<string | null> = signal(null);
  iconName: WritableSignal<string | null> = signal(null);
  bckgColor: WritableSignal<string | null> = signal(null);
  color: WritableSignal<string | null> = signal(null);
  tagClass: WritableSignal<boolean> = signal(false);
  private readonly injector: Injector = inject(Injector);
  initChipEffect = effect(
    () => {
      const chip = this.chip();
      const isTag = this.isTag();

      // TODO: Refactor component inputs to accept [category, label, iconName, bckgColor, color ...] instead of chip to avoid this logic
      if (chip?.hasOwnProperty('criteriaKey')) {
        const filterChip = chip as Filter;
        this.category.set(filterChip.criteriaLabel as string);
        this.iconName.set(null);
        this.color.set('');
        this.bckgColor.set('');
        if (filterChip.dateLabel) {
          this.dateLabel.set(filterChip.dateLabel);
        } else {
          this.label.set(filterChip.label as string);
        }
      }

      if (chip?.hasOwnProperty('tag')) {
        const tagBodyChip = chip as TagBody;
        this.category.set(tagBodyChip.tagCategory.name as string);
        this.label.set(tagBodyChip.tag.name);
        this.iconName.set(tagBodyChip.tagCategory.concept?.toLowerCase() as string);
        this.color.set('');
        this.bckgColor.set(tagBodyChip.tagCategory.color as string);
        this.tagClass.set(!!tagBodyChip.tag.selected);
      }

      if (isTag) {
        const TagCategoryChip = chip as TagCategory;
        this.category.set(TagCategoryChip.name as string);
        this.label.set(TagCategoryChip.labels?.[0].name as string);
        this.iconName.set(TagCategoryChip.concept?.toLowerCase() as string);
        this.color.set('');
        this.bckgColor.set(TagCategoryChip.color as string);
      }
    },
    { injector: this.injector, allowSignalWrites: true }
  );

  onChipButtonClick(event: MouseEvent, chip: any): void {
    event.stopPropagation();
    this.chipButtonClick.emit(chip);
  }

  onChipClick(event: MouseEvent, chip: any): void {
    event.stopPropagation();
    this.chipClick.emit(chip);
  }
}
