import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { GridEngineModule } from '@iot-platform/grid-engine';
import { EventSeverityDisplayModule, FilterEngineModule, IotPlatformUiModule } from '@iot-platform/iot-platform-ui';
import { DateFormatPipe, NumberFormatPipe, UserNamePipe } from '@iot-platform/pipes';

import { SharedModule } from '@iot-platform/shared';
import { MasterViewEngineModule } from '@iot-platform/table-engine';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { EventsByConceptGridModule } from '../../components/events-by-concept-grid/events-by-concept-grid.module';
import { PoEventsEffects } from './+state/effects/po-events.effects';
import * as fromPoEventRules from './+state/reducers';
import { NumberInputComponent } from './components/po-event-configure-form/controls/number-input/number-input.component';
import { StringInputComponent } from './components/po-event-configure-form/controls/string-input/string-input.component';
import { TextInputComponent } from './components/po-event-configure-form/controls/text-input/text-input.component';
import { PoEventConfigureFormComponent } from './components/po-event-configure-form/po-event-configure-form.component';
import { PoEventDetailPopupComponent } from './components/po-event-detail-popup/po-event-detail-popup.component';
import { PoEventGeneratedEventsTabComponent } from './components/po-event-generated-events-tab/po-event-generated-events-tab.component';
import { PoEventInfoComponent } from './components/po-event-info/po-event-info.component';
import { PoEventDetailsShellComponent } from './containers/po-event-details-shell/po-event-details-shell.component';
import { PoEventsShellComponent } from './containers/po-events-shell/po-events-shell.component';

const poEventRulesRoutes: Routes = [
  { path: '', component: PoEventsShellComponent },
  { path: ':poEventId', component: PoEventDetailsShellComponent }
];

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    StoreModule.forFeature(fromPoEventRules.poEventRulesFeatureKey, fromPoEventRules.reducers),
    EffectsModule.forFeature([PoEventsEffects]),
    RouterModule.forChild(poEventRulesRoutes),
    MasterViewEngineModule,
    IotPlatformUiModule,
    EventSeverityDisplayModule,
    GridEngineModule,
    FilterEngineModule,
    EventsByConceptGridModule,
    DateFormatPipe,
    UserNamePipe,
    NumberFormatPipe
  ],
  declarations: [
    PoEventsShellComponent,
    PoEventDetailPopupComponent,
    PoEventConfigureFormComponent,
    NumberInputComponent,
    StringInputComponent,
    TextInputComponent,
    PoEventDetailsShellComponent,
    PoEventInfoComponent,
    PoEventGeneratedEventsTabComponent
  ],
  exports: [RouterModule]
})
export class PoEventsModule {}
