@if (item().display) {
  <div
    (click)="handleClick($event)"
    [class.inSubMenu]="item()?.level > 0"
    [style.border-left]="'6px solid ' + item().leftBorderColor"
    class="container iot-platform-ui-sidenav-item"
    data-cy="iot-platform-ui-sidenav-item"
  >
    @if (item().hasChildren) {
      <div
        #trigger="matMenuTrigger"
        (mouseenter)="mouseEnter($event, trigger)"
        (mouseleave)="mouseLeave($event)"
        [matMenuTriggerFor]="subMenu"
        class="iot-platform-ui-sidenav-sub-item"
        data-cy="iot-platform-ui-sidenav-sub-item"
      >
        @if (item().path) {
          <a
            [lines]="1"
            [ngClass]="{ 'active-item': url() === item().path && item().disableActiveState !== true }"
            [routerLink]="[item().path ?? '']"
            class="item-container iot-platform-ui-sidenav-sub-item-link"
            data-cy="iot-platform-ui-sidenav-sub-item-link"
            mat-list-item
          >
            @if (item().icon) {
              <mat-icon [svgIcon]="item().icon" class="item-icon" matListItemIcon></mat-icon>
            }
            <p [innerHTML]="item().name | translate" class="item-content" matListItemTitle></p>
            <div matListItemMeta>
              <mat-icon class="chevron-right">chevron_right</mat-icon>
            </div>
          </a>
        }
      </div>
      <mat-menu #subMenu="matMenu" backdropClass="sidenav-backdrop-class" class="sidenav-menu-panel" xPosition="after">
        @for (navBlock of item().submenu; track navBlock.id) {
          <div>
            <iot-platform-ui-sidenav-block [navBlock]="navBlock" class="sidenav-menu-panel-block" />
          </div>
        }
      </mat-menu>
    } @else {
      <a
        (click)="navigate()"
        (mouseenter)="mouseEnter($event)"
        (mouseleave)="mouseLeave($event)"
        [lines]="1"
        [ngClass]="{ 'active-item': url() === item().path && item().disableActiveState !== true }"
        class="item-container iot-platform-ui-sidenav-item-link"
        data-cy="iot-platform-ui-sidenav-item-link"
        mat-list-item
      >
        @if (item().icon) {
          <mat-icon [svgIcon]="item().icon" class="item-icon" matListItemIcon></mat-icon>
        }
        <p [innerHTML]="item().name | translate" class="item-content" matListItemTitle title="{{ item().name | translate }}"></p>
        @if (item().rightIcons) {
          <div class="right-icon" matListItemMeta>
            @for (icon of item().rightIcons; track icon) {
              <mat-icon class="item-icon">{{ icon }}</mat-icon>
            }
          </div>
        }
      </a>
    }
  </div>
}
