import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { LinkifyPipe } from '@iot-platform/pipes';
import { ContactsStoreModule } from '@iot-platform/shared/data-access/contacts';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ExportSpreadsheetsEffects } from './+state/effects/export-spreadsheets.effects';
import { GraphsEffects } from './+state/effects/graphs.effects';
import { NotificationsEffects } from './+state/effects/notifications.effects';
import { SitesCommentsEffects } from './+state/effects/sites-comments.effects';
import { SitesEffects } from './+state/effects/sites.effects';
import * as fromSites from './+state/reducers';
import { SiteOverviewModule } from './containers/site-overview/site-overview.module';
import { SitesShellModule } from './containers/sites-shell/sites-shell.module';

@NgModule({
  imports: [
    StoreModule.forFeature(fromSites.sitesFeatureKey, fromSites.reducers),
    EffectsModule.forFeature([SitesEffects, GraphsEffects, NotificationsEffects, ExportSpreadsheetsEffects, SitesCommentsEffects]),
    ContactsStoreModule,
    SiteOverviewModule,
    SitesShellModule,
    LinkifyPipe
  ],
  exports: [RouterModule]
})
export class SitesModule {}
