import { Component, effect, inject, InjectionToken, input, Signal } from '@angular/core';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { AnalyticsService } from '@iot-platform/core';
// TODO This dependency should be reviewed its cause an app crash
// import { PopupComponent, PopupModule } from '@iot-platform/iot-platform-ui';
import { PopupComponent } from 'libs/iot-platform/iot-platform-ui/src/lib/ui/components/popup/popup.component';
import { PopupModule } from 'libs/iot-platform/iot-platform-ui/src/lib/ui/components/popup/popup.module';
import { LoaderComponent } from './loader/loader.component';
import { NotificationService } from './notification.service';

export const DISABLE_ERROR_MESSAGES_AFTER_LOGOUT = new InjectionToken<boolean>('DISABLE_ERROR_MESSAGES_AFTER_LOGOUT');

@Component({
  standalone: true,
  imports: [LoaderComponent, MatSnackBarModule, MatDialogModule, PopupModule],
  selector: 'iot-platform-notification',
  templateUrl: './notification.component.html'
})
export class NotificationComponent {
  private readonly disableErrorMessagesAfterLogout: boolean | null = inject(DISABLE_ERROR_MESSAGES_AFTER_LOGOUT, { optional: true });
  public readonly snackBar: MatSnackBar = inject(MatSnackBar);
  public readonly notificationService: NotificationService = inject(NotificationService);
  public readonly dialog: MatDialog = inject(MatDialog);
  analytic: AnalyticsService = new AnalyticsService('display_error_popup');
  loader: Signal<boolean> = this.notificationService.loader;
  isLoggedOut = input(false, {
    transform: (value: boolean) => {
      if (this.disableErrorMessagesAfterLogout) {
        return value;
      }
      return null;
    }
  });

  displaySuccessEffect = effect(
    () => {
      const success = this.notificationService.success();
      if (success) {
        this.displaySuccess(success);
      }
    },
    { allowSignalWrites: true }
  );

  displayErrorEffect = effect(
    () => {
      const error = this.notificationService.error();
      if (error) {
        this.displayError(error);
      }
    },
    { allowSignalWrites: true }
  );

  // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
  public openErrorPopup(value: string | { code: string; error: string } | any) {
    // eslint-disable-next-line no-prototype-builtins
    if (value.hasOwnProperty('type')) {
      try {
        this.analytic.logError(
          value.type,
          `${value.error.error.code} - ${value.error.error.error}`,
          `${value.type} : ${value.error.error.code} - ${value.error.error.error}`
        );
      } catch (ex) {
        //
      }
    }

    this.dialog.open(PopupComponent, {
      width: '500px',
      disableClose: true,
      data: { type: 'error', value }
    });
  }

  private displaySuccess(message: string, action = '', duration = 4000) {
    this.snackBar.open(message, action, { duration });
  }

  // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
  private displayError(error: string | { code: string; error: string } | any) {
    if (this.disableErrorMessagesAfterLogout) {
      if (this.isLoggedOut() === false) {
        this.openErrorPopup(error);
      }
    } else {
      this.openErrorPopup(error);
    }
  }
}
