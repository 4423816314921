import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { CustomEncoder, ENVIRONMENT } from '@iot-platform/core';
import { BusinessProfile, CommonApiListResponse, Environment, UserAccount } from '@iot-platform/models/common';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserInfoPopupService {
  private readonly environment: Environment = inject(ENVIRONMENT);
  private readonly http: HttpClient = inject(HttpClient);

  getUserAccountById(userId: string): Observable<UserAccount> {
    return this.http.get<UserAccount>(`${this.environment.api.url}${this.environment.api.endpoints.users}/${userId}`);
  }

  getBusinessProfilesByUserId(userId: string): Observable<BusinessProfile[]> {
    let params: HttpParams = new HttpParams({ encoder: new CustomEncoder() });
    params = params.set('userId', userId);

    return this.http
      .get<CommonApiListResponse<BusinessProfile>>(`${this.environment.api.url}${this.environment.api.endpoints.businessProfiles}`, { params })
      .pipe(map((response) => response.content));
  }
}
