<form [formGroup]="inputForm" fxFlex>
  <mat-form-field color="accent" fxFlex>
    <mat-label>{{ 'PO_EVENTS.CREATION_CONFIGURATION_POPUP.INPUT_LIST.' + (placeholder$ | async) | translate }}</mat-label>
    <mat-select (selectionChange)="onValueChange()" formControlName="value" required>
      @for (val of choices; track val) {
        <mat-option [value]="val">{{ val }}</mat-option>
      }
    </mat-select>
    @if (value.hasError('required')) {
      <mat-error>{{ 'PO_EVENTS.CREATION_CONFIGURATION_POPUP.REQUIRED' | translate }}</mat-error>
    }
  </mat-form-field>
</form>
