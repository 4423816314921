<div class="ag-theme-material grid-wrapper">
  <ag-grid-angular
    (firstDataRendered)="onFirstDataRendered($event)"
    (gridReady)="onGridReady($event)"
    (newColumnsLoaded)="onGridColumnsChanged()"
    (rowSelected)="rowSelected$.next($event)"
    (sortChanged)="onSortChanged($event)"
    [accentedSort]="true"
    [colResizeDefault]="'shift'"
    [columnDefs]="columnDefs"
    [gridOptions]="gridOptions"
    [navigateToNextCell]="navigateToNextCell"
    [navigateToNextHeader]="navigateToNextHeader"
    [rowData]="rowData"
    [tabToNextCell]="tabToNextCell"
    [tabToNextHeader]="tabToNextHeader"
    rowHeight="38"
    rowSelection="multiple"
    style="flex: 1"
  >
  </ag-grid-angular>
  @if (displayPagination) {
    <grid-engine-paginator (pageChange)="onPageChange($event)" [pagination]="pagination$ | async"></grid-engine-paginator>
  }
</div>
<ng-template #exportViewRef></ng-template>
