import { IHeaderAngularComp } from '@ag-grid-community/angular';
import { Component, ElementRef } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { CustomHeaderParams } from '../custom-headers.params';
import { HeaderShellComponent } from '../header-shell/header-shell.component';

@Component({
  standalone: true,
  selector: 'grid-engine-icon-and-text-header-cell',
  templateUrl: './icon-and-text-header-cell.component.html',
  imports: [HeaderShellComponent, MatIcon, TranslateModule],
  styleUrls: ['./icon-and-text-header-cell.component.scss']
})
export class IconAndTextHeaderCellComponent implements IHeaderAngularComp {
  private elementRef: ElementRef;
  params: CustomHeaderParams;
  public sorted: string;

  constructor(elementRef: ElementRef) {
    this.elementRef = elementRef;
  }

  agInit(params: CustomHeaderParams): void {
    this.params = params;
    this.params.column.addEventListener('sortChanged', this.onSortChanged.bind(this));
    this.onSortChanged();
  }

  refresh(): boolean {
    return false;
  }

  onSortRequested(order, event) {
    this.params.setSort(order, event.shiftKey);
  }

  onSortChanged() {
    if (this.params.column.isSortAscending()) {
      this.sorted = 'asc';
    } else if (this.params.column.isSortDescending()) {
      this.sorted = 'desc';
    } else {
      this.sorted = '';
    }
  }

  private querySelector(selector: string) {
    return this.elementRef.nativeElement.querySelector('.customHeaderMenuButton', selector) as HTMLElement;
  }
}
