import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { fromAuth } from '@iot-platform/auth';
import { CustomEncoder } from '@iot-platform/core';
import { CommonApiListResponse, Entity, Environment, Filter, PlatformRequest, PlatformResponse } from '@iot-platform/models/common';
import { PoEventAlgorithm, PoEventRule } from '@iot-platform/models/i4b';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, of } from 'rxjs';
import { first, map, mergeMap, withLatestFrom } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PoEventsService {
  constructor(
    @Inject('environment') private environment: Environment,
    private readonly http: HttpClient,
    private readonly store: Store
  ) {}

  listPoEventRules(request: PlatformRequest): Observable<PlatformResponse> {
    let params: HttpParams = new HttpParams({ encoder: new CustomEncoder() });

    if (request.limit) {
      params = params.set('limit', request.limit.toString(10));
    }

    if (request.page) {
      params = params.set('page', request.page.toString(10));
    }

    if (request.filters) {
      request.filters.forEach((filter: Filter) => {
        params = params.append(filter.criteriaKey, filter.value);
      });
    }

    const sessionEntity$ = this.store.select(fromAuth.selectSelectedEntityForSession);
    const request$ = this.http.get<any>(`${this.environment.api.url}${this.environment.api.endpoints.poEventRules}`, { params });

    return combineLatest([sessionEntity$, request$]).pipe(
      first(),
      mergeMap(([sessionEntity, response]) =>
        of({
          data: response.content.map((rule: PoEventRule) => ({ ...rule, isEditable: this.calculateEditability(rule, sessionEntity) })),
          currentPage: response.page.curPage,
          hasMore: response.page.hasMore,
          limit: response.page.limit,
          maxPage: response.page.maxPage,
          total: response.page.total
        })
      )
    );
  }

  getRuleById(ruleId: string): Observable<PoEventRule> {
    return this.http.get<PoEventRule>(`${this.environment.api.url}${this.environment.api.endpoints.poEventRules}/${ruleId}`).pipe(
      withLatestFrom(this.store.select(fromAuth.selectSelectedEntityForSession)),
      map(([poEventRule, sessionEntity]) => ({ ...poEventRule, isEditable: this.calculateEditability(poEventRule, sessionEntity) }))
    );
  }

  updateRule(rule: PoEventRule): Observable<PoEventRule> {
    return this.http
      .patch<PoEventRule>(`${this.environment.api.url}${this.environment.api.endpoints.poEventRules}/${rule.id}`, {
        name: rule.name
      })
      .pipe(
        withLatestFrom(this.store.select(fromAuth.selectSelectedEntityForSession)),
        map(([poEventRule, sessionEntity]) => ({ ...poEventRule, isEditable: this.calculateEditability(poEventRule, sessionEntity) }))
      );
  }

  fullUpdateRule(rule: PoEventRule): Observable<PoEventRule> {
    return this.http.patch<PoEventRule>(`${this.environment.api.url}${this.environment.api.endpoints.poEventRules}/${rule.id}`, {
      concept: rule.concept,
      algo: rule.algo,
      name: rule.name,
      isActive: rule.isActive,
      severity: rule.severity,
      backToNormal: rule.backToNormal,
      filters: rule.filters,
      params: rule.params,
      class: rule.class
    });
  }

  addRule(rule: any): Observable<PoEventRule> {
    return this.http
      .post<PoEventRule>(`api/v1/entities/${rule.entity.id}/po-event-rules`, {
        algo: rule.algo,
        name: rule.name,
        concept: rule.concept,
        filters: []
      })
      .pipe(map((response) => ({ ...response, isEditable: true })));
  }

  deletePoEventRule(rule: PoEventRule): Observable<PoEventRule> {
    return this.http.delete<PoEventRule>(`${this.environment.api.url}${this.environment.api.endpoints.poEventRules}/${rule.id}`).pipe(map(() => rule));
  }

  calculateEditability(rule: PoEventRule, sessionEntity: Entity): boolean {
    const found = rule.entityPath.find((ent: Entity) => ent.id === sessionEntity.id);
    return rule.entity.id === sessionEntity.id || !!found;
  }

  getPoEventAlgorithms(): Observable<PoEventAlgorithm[]> {
    return this.http.get<PoEventAlgorithm[]>(`${this.environment.api.url}${this.environment.api.endpoints.poEventAlgos}`);
  }

  getSeverities(): Observable<string[]> {
    return this.http.get<string[]>('/assets/engines/lists/event-severities.json');
  }

  isNameUnique(ruleName: string, entityId: string): Observable<boolean> {
    let params: HttpParams = new HttpParams();
    params = params.set('page', '0');
    params = params.set('limit', '0');
    params = params.append('name', ruleName);
    params = params.append('entityId', entityId);
    params = params.append('exactParam', 'name');
    params = params.append('includeSubEntities', false);
    params = params.append('includeParentEntities', false);
    return this.http
      .get<CommonApiListResponse<PoEventRule>>(`${this.environment.api.url}${this.environment.api.endpoints.poEventRules}`, { params })
      .pipe(map((data: CommonApiListResponse<PoEventRule>) => data.page.total === 0));
  }
}
