import { Component, inject, signal, WritableSignal } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { TagCategory } from '@iot-platform/models/common';
import { TranslateModule } from '@ngx-translate/core';
import { ManageTagsFormComponent } from '../manage-tags-form';

interface ManageTagsFormData {
  concepts: string[];
  openOnConcept?: string;
  selectedTags: TagCategory[];
  objectName: string;
  currentEntityId: string;
  multiSelection: boolean;
  editable: boolean;
  withChildren: boolean;
  withParents: boolean;
  joinable: boolean;
  minimumSelectionCount?: number;
  enforceMandatoryCategories?: boolean;
}

@Component({
  selector: 'iot-platform-ui-manage-tags-dialog',
  standalone: true,
  imports: [MatCardModule, MatButtonModule, TranslateModule, MatToolbarModule, MatIconModule, FlexLayoutModule, ManageTagsFormComponent],
  templateUrl: './manage-tags-dialog.component.html',
  styleUrl: './manage-tags-dialog.component.scss'
})
export class ManageTagsDialogComponent {
  public readonly dialogRef: MatDialogRef<ManageTagsDialogComponent> = inject(MatDialogRef<ManageTagsDialogComponent>);
  public readonly data: ManageTagsFormData = inject(MAT_DIALOG_DATA);

  tagsToSave: WritableSignal<TagCategory[] | null> = signal(null);
  // TODO remap ça avec le form, cf bug #125119 (tag editor)
  canClose: WritableSignal<boolean> = signal(false);

  onFormChange(tagsToSave: TagCategory[] | null): void {
    this.tagsToSave.set(tagsToSave);
  }
}
