<div class="ag-theme-material grid-wrapper" data-cy="grid-engine-grid-page">
  <ag-grid-angular
    (firstDataRendered)="onFirstDataRendered($event)"
    (gridReady)="onGridReady($event)"
    (newColumnsLoaded)="onGridColumnsChanged()"
    (rowClicked)="selectRow.emit($event)"
    (rowSelected)="rowSelected$.next($event)"
    (selectionChanged)="onSelectionChanged($event)"
    (sortChanged)="onSortChange($event)"
    [accentedSort]="true"
    [colResizeDefault]="'shift'"
    [columnDefs]="columnDefs"
    [debug]="false"
    [defaultColDef]="defaultColDef"
    [getLocaleText]="getLocaleText"
    [gridOptions]="gridOptions"
    [groupDisplayType]="'multipleColumns'"
    [navigateToNextCell]="navigateToNextCell"
    [navigateToNextHeader]="navigateToNextHeader"
    [rowClassRules]="rowClassRules"
    [rowData]="innerGridData$ | async"
    [sideBar]="sideBar"
    [tabToNextCell]="tabToNextCell"
    [tabToNextHeader]="tabToNextHeader"
    rowSelection="multiple"
    style="flex: 2"
  >
  </ag-grid-angular>
  @if (!hidePaginator) {
    <grid-engine-paginator (pageChange)="onPageChange($event)" [pagination]="grid?.data?.response.pagination"></grid-engine-paginator>
  }
</div>
<ng-template #exportViewRef></ng-template>
