import { Component, OnDestroy, OnInit, Signal } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Router } from '@angular/router';
import { HistoryLogsPopupComponent } from '@iot-platform/audit-trail';
import { AuthorizationConcept, AuthorizationService, AuthorizationType, fromAuth } from '@iot-platform/auth';
import { AnalyticsService } from '@iot-platform/core';
import {
  DELETE_BUTTON_CONFIG,
  IotToolbarDefaultButton,
  IotToolbarDispatchActionType,
  OPEN_HISTORY_BUTTON_CONFIG,
  PopupComponent,
  REFRESH_BUTTON_CONFIG
} from '@iot-platform/iot-platform-ui';
import { IotToolbarEvent, ToolbarSize } from '@iot-platform/models/common';
import { PoEventRule } from '@iot-platform/models/i4b';
import { Store } from '@ngrx/store';
import { combineLatest, Subject } from 'rxjs';
import { filter, takeUntil, withLatestFrom } from 'rxjs/operators';
import { POEventRulesUiActions } from '../../+state/actions';
import * as fromPOEventRule from '../../+state/reducers';
import { PoEventConfigureFormComponent } from '../../components/po-event-configure-form/po-event-configure-form.component';
import { PoEventCreateFormComponent } from '../../components/po-event-create-form/po-event-create-form.component';

@Component({
  selector: 'iot4bos-ui-po-event-details-shell',
  templateUrl: './po-event-details-shell.component.html',
  styleUrls: ['./po-event-details-shell.component.scss']
})
export class PoEventDetailsShellComponent implements OnInit, OnDestroy {
  analytic: AnalyticsService = new AnalyticsService('po_rule_info_page');

  selectedRule: Signal<PoEventRule | undefined> = this.store.selectSignal(fromPOEventRule.selectSelectedPOEventRule);

  // Privileges
  canUpdateRule = false;
  canDeleteRule = false;
  canReadEvent = false;
  canUpdateEvent = false;
  canDisplayAuditTrail = false;

  ruleDetailButtonList: IotToolbarDefaultButton[] = [];

  userPermissions: {
    key: string;
    value: boolean;
  }[] = [];
  toolbarSize = ToolbarSize.SMALL;

  destroy$: Subject<void> = new Subject();

  constructor(
    private readonly store: Store,
    private readonly router: Router,
    private readonly authorizationService: AuthorizationService,
    private readonly dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.canUpdateRule = this.authorizationService.applyAuthorization(AuthorizationConcept.PO_EVENT_RULE, AuthorizationType.UPDATE);
    this.canDeleteRule = this.authorizationService.applyAuthorization(AuthorizationConcept.PO_EVENT_RULE, AuthorizationType.DELETE);
    this.canReadEvent = this.authorizationService.applyAuthorization(AuthorizationConcept.EVENT, AuthorizationType.READ);
    this.canUpdateEvent = this.authorizationService.applyAuthorization(AuthorizationConcept.EVENT, AuthorizationType.UPDATE);
    this.canDisplayAuditTrail = this.authorizationService.applyAuthorization(AuthorizationConcept.AUDIT_TRAIL, AuthorizationType.READ);

    this.userPermissions = [
      { key: 'canUpdateRule', value: this.canUpdateRule },
      { key: 'canReadEvent', value: this.canReadEvent },
      { key: 'canUpdateEvent', value: this.canUpdateEvent },
      { key: 'canDisplayAuditTrail', value: this.canDisplayAuditTrail }
    ];

    combineLatest([
      this.store.select(fromAuth.selectSelectedBusinessProfileForAccount),
      this.store.select(fromAuth.selectAccount),
      this.store.select(fromPOEventRule.selectSelectedPOEventRule)
    ])
      .pipe(
        takeUntil(this.destroy$),
        filter(([bp, user]) => !!bp && !!user),
        withLatestFrom(this.store.select(fromPOEventRule.selectSelectedPoEventRuleId))
      )
      .subscribe(([[_, __, selectedRule], selectedRuleId]) => {
        if (!selectedRule) {
          this.store.dispatch(
            POEventRulesUiActions.loadPOEventRule({ ruleToLoadId: selectedRuleId ?? (this.router.routerState.snapshot.url.split('/').pop() as string) })
          );
        }
      });
    this.store
      .select(fromPOEventRule.selectSelectedPOEventRule)
      .pipe(takeUntil(this.destroy$))
      .subscribe((selectedRule) => {
        if (selectedRule) {
          this.initToolbarButtonList();
        }
      });
    this.initToolbarButtonList();
  }

  initToolbarButtonList(): void {
    this.ruleDetailButtonList = [
      new IotToolbarDefaultButton({ ...OPEN_HISTORY_BUTTON_CONFIG, displayButton: this.canDisplayAuditTrail }, 0),
      new IotToolbarDefaultButton({ ...REFRESH_BUTTON_CONFIG, tooltip: 'PO_EVENTS.INFO_PAGE.REFRESH_BUTTON' }, 2),
      new IotToolbarDefaultButton(
        { ...DELETE_BUTTON_CONFIG, displayButton: this.canDeleteRule && this.selectedRule()?.isEditable, disabled: this.selectedRule()?.isActive },
        1
      )
    ];
  }

  onSelectedTabChange(event: MatTabChangeEvent): void {
    this.analytic.log('tab_change', `${event.tab.textLabel}`);
  }

  onToolbarEvent(event: IotToolbarEvent): void {
    switch (event.type) {
      case IotToolbarDispatchActionType.REFRESH_PAGE:
        this.refreshRule();
        break;
      case IotToolbarDispatchActionType.DELETE_ELEMENT:
        this.deleteRule();
        break;
      case IotToolbarDispatchActionType.OPEN_HISTORY:
        this.openChangeLog();
        break;
      default:
        break;
    }
  }

  onClickBack(): void {
    this.router.navigate(['po-event-rules']);
  }

  refreshRule(): void {
    this.analytic.log('toolbar_actions', 'refresh_rule');
    this.store.dispatch(POEventRulesUiActions.loadPOEventRule({ ruleToLoadId: this.selectedRule().id as unknown as string }));
  }

  editRule(): void {
    this.dialog
      .open(PoEventCreateFormComponent, {
        width: '700px',
        data: { rule: this.selectedRule() }
      })
      .afterClosed()
      .subscribe((result: { action: string; rule: PoEventRule }) => {
        if (result && result.action === 'EDIT') {
          this.store.dispatch(POEventRulesUiActions.updatePOEventRule({ poEventRuleToUpdate: result.rule }));
        }
      });
  }

  configureRule(): void {
    this.dialog
      .open(PoEventConfigureFormComponent, {
        width: '900px',
        disableClose: true,
        data: { element: this.selectedRule() }
      })
      .afterClosed()
      .subscribe((poEventRuleToConfigure: PoEventRule) => {
        if (poEventRuleToConfigure) {
          this.store.dispatch(POEventRulesUiActions.configurePOEventRule({ poEventRuleToConfigure }));
        }
      });
  }

  deleteRule(): void {
    this.analytic.log('toolbar_actions', 'open_delete_rule');
    this.dialog
      .open(PopupComponent, {
        width: '500px',
        disableClose: true,
        data: { type: 'delete', value: this.selectedRule().name }
      })
      .afterClosed()
      .subscribe((result: boolean) => {
        if (result) {
          this.analytic.log('toolbar_actions', 'delete_rule');
          this.store.dispatch(POEventRulesUiActions.deletePOEventRule({ poEventRuleToDelete: this.selectedRule() }));
        }
      });
  }

  openChangeLog(): void {
    this.analytic.log('poe_info_actions', 'open_history');
    this.dialog.open(HistoryLogsPopupComponent, {
      data: { concept: 'po_event_rule', elementId: this.selectedRule().id, elementName: this.selectedRule().name },
      disableClose: true,
      maxWidth: '1300px',
      minWidth: '1300px'
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
