import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IotPlatformUiModule, provideTagEditorSettings, TagCategoryListByConceptModule } from '@iot-platform/iot-platform-ui';
import { SharedModule } from '@iot-platform/shared';
import { IconModule } from '@iot-platform/shared/components';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { BusinessProfilesListModule } from '../../components/business-profiles-list/business-profiles-list.module';
import { OrganizationConceptsSelectionModule } from '../../components/organization-concepts-selection/organization-concepts-selection.module';
import { RolesCrudModule } from '../../components/roles-crud/roles-crud.module';
import { RolesListModule } from '../../components/roles-list/roles-list.module';
import { UsersListModule } from '../../components/users-list/users-list.module';
import { TagEditorService } from '../../services/tag-editor.service';
import { OrganizationDetailComponent } from './components/organization-detail/organization-detail.component';
import { OrganizationsListComponent } from './components/organizations-list/organizations-list.component';
import { AdminOrganizationsComponent } from './containers/admin-organizations.component';
import { OrganizationsService } from './services/organizations.service';
import { AdminOrganizationsAdministratorsEffects } from './state/effects/admin-organizations-administrators.effects';
import { AdminOrganizationsRolesEffects } from './state/effects/admin-organizations-roles.effects';
import { AdminOrganizationsTagsEffects } from './state/effects/admin-organizations-tags.effects';
import { AdminOrganizationsEffects } from './state/effects/admin-organizations.effects';
import * as fromOrganizations from './state/reducers';

@NgModule({
  declarations: [AdminOrganizationsComponent, OrganizationsListComponent, OrganizationDetailComponent],
  exports: [AdminOrganizationsComponent],
  imports: [
    CommonModule,
    SharedModule,
    StoreModule.forFeature(fromOrganizations.adminOrganizationsFeatureKey, fromOrganizations.reducers),
    EffectsModule.forFeature([
      AdminOrganizationsEffects,
      AdminOrganizationsRolesEffects,
      AdminOrganizationsAdministratorsEffects,
      AdminOrganizationsRolesEffects,
      AdminOrganizationsTagsEffects
    ]),
    BusinessProfilesListModule,
    RolesListModule,
    RolesCrudModule,
    UsersListModule,
    TagCategoryListByConceptModule,
    IconModule,
    OrganizationConceptsSelectionModule,
    IotPlatformUiModule
  ],
  providers: [
    provideTagEditorSettings({
      tagEditorService: TagEditorService
    }),
    OrganizationsService
  ]
})
export class AdminOrganizationsModule {}
