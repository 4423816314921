import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatToolbarModule } from '@angular/material/toolbar';
import {
  CardLoaderModule,
  ChipModule,
  ColorPickerModule,
  ImageSelectorModule,
  ManageTagsFormComponent,
  PopupModule,
  ProgressBarButtonModule,
  TagCategoryListByConceptModule
} from '@iot-platform/iot-platform-ui';
import { SharedModule } from '@iot-platform/shared';
import { IconModule } from '@iot-platform/shared/components';
import { UserPreferencesService, UsersModule } from '@iot-platform/users';
import { TranslateModule } from '@ngx-translate/core';
import { MasterViewEngineModule } from './components/master-view-engine/master-view-engine.module';
import { AdminSettingsPopupComponent } from './components/table/settings/admin-settings-popup/admin-settings-popup.component';
import { SettingsPopupComponent } from './components/table/settings/settings-popup/settings-popup.component';

@NgModule({
  imports: [
    CommonModule,
    MasterViewEngineModule,
    SharedModule,
    MatIconModule,
    MatMenuModule,
    MatToolbarModule,
    MatGridListModule,
    MatProgressBarModule,
    FlexLayoutModule,
    TranslateModule,
    CardLoaderModule,
    ImageSelectorModule,
    ChipModule,
    IconModule,
    ColorPickerModule,
    PopupModule,
    ManageTagsFormComponent,
    TagCategoryListByConceptModule,
    ProgressBarButtonModule,
    MatProgressSpinnerModule,
    DragDropModule,
    UsersModule
  ],
  exports: [MasterViewEngineModule],
  providers: [UserPreferencesService],
  declarations: [SettingsPopupComponent, AdminSettingsPopupComponent]
})
export class TableEngineModule {}
