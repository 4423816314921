import { Entity } from '@iot-platform/models/common';
import { AssetTemplate } from '@iot-platform/models/i4b';

export class AssetTemplateHelpers {
  public static calculateIsEditable(template: AssetTemplate, sessionEntity: Entity): boolean {
    return (
      (template?.entity && sessionEntity && template.entity.id === sessionEntity.id) ||
      !!template?.entityPath?.find((entity: Entity) => entity.id === sessionEntity?.id)
    );
  }
}
