<mat-card class="dialog-card">
  <mat-card-title class="dialog-card-header">
    <mat-toolbar class="dialog-toolbar" color="accent" fxLayout="row" fxLayoutAlign="space-between center">
      <h1 class="dialog-card-title">{{ title$ | async }}</h1>
      <button (click)="close()" class="regular-round-button" mat-icon-button>
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar>
  </mat-card-title>

  <mat-card-content class="dialog-card-content">
    <form [formGroup]="form" class="p-20" fxFlex fxLayout="column" fxLayoutAlign="space-between">
      <mat-form-field color="accent">
        <mat-label>{{ 'PO_EVENTS.CREATION_CONFIGURATION_POPUP.NAME' | translate }}</mat-label>
        <input #nameInput formControlName="name" matInput maxlength="50" pattern="\S.*" required />
        @if (name.invalid && (name.errors?.maxlength || name.errors?.pattern)) {
          <mat-error>{{ 'ASSETS.VARIABLE_FORM.ERROR_MESSAGE.REQUIRED' | translate }} </mat-error>
        }
        @if (name.invalid && name.errors?.nameExists) {
          <mat-error>{{ 'FAVORITE_VIEW.FORM.ERROR_MESSAGE.DUPLICATE' | translate }} </mat-error>
        }
        <mat-hint align="end">{{ nameInput.value.length || 0 }}/50</mat-hint>
      </mat-form-field>

      @defer {
        <iot-platform-ui-async-autocomplete
          (reset)="onResetEntity()"
          (search)="onResetEntity()"
          (selectionChanged)="onEntitySelection($event)"
          [data]="entities()"
          [disabled]="!!data.rule"
          [displayKey]="'label'"
          [displaySearchIcon]="false"
          [filterKey]="'label'"
          [initialItem]="data.rule?.entity ?? undefined"
          [minLength]="1"
          [placeholder]="'IOT_DICTIONARY.ENTITY'"
          [required]="true"
          [showSpinner]="!entities()"
        />
      }

      <mat-form-field color="accent">
        <mat-label>{{ 'IOT_DICTIONARY.RULE_TYPE' | translate }}</mat-label>
        <mat-select (selectionChange)="onAlgoSelectionChange($event.value)" formControlName="type" required>
          @for (algo of algos(); track algo) {
            <mat-option [value]="algo.name">{{ algo.name }}</mat-option>
          }
        </mat-select>
      </mat-form-field>

      <mat-form-field color="accent">
        <mat-label>{{ 'IOT_DICTIONARY.CONCEPT' | translate }}</mat-label>
        <mat-select formControlName="concept" required>
          @for (concept of selectedAlgo()?.concepts; track concept) {
            <mat-option [value]="concept">{{ concept }}</mat-option>
          }
        </mat-select>
      </mat-form-field>

      <p class="dialog-form-tips">{{ 'ASSETS.VARIABLE_FORM.MANDATORY_FIELDS' | translate }}</p>
    </form>
  </mat-card-content>

  <mat-card-actions class="dialog-card-actions" fxLayout="row" fxLayoutAlign="end center">
    <button (click)="close()" class="button-regular" mat-button>{{ 'ASSETS.VARIABLE_FORM.CANCEL' | translate }}</button>
    <button (click)="save('ADD')" [disabled]="isButtonDisabled()" class="button-regular" color="accent" mat-raised-button>
      {{ (data.rule ? 'IOT_DICTIONARY.UPDATE' : 'IOT_DICTIONARY.CREATE') | translate }}
    </button>
    @if (!data.rule) {
      <button (click)="save('CONFIGURE')" [disabled]="isButtonDisabled()" class="button-regular" color="accent" mat-raised-button>
        {{ 'ASSETS.VARIABLE_FORM.CREATE_CONFIGURE' | translate }}
      </button>
    }
  </mat-card-actions>
</mat-card>
