<mat-sidenav-container (backdropClick)="closeAllPanels()" class="device-overview--sidenav-container" hasBackdrop="true">
  <mat-sidenav-content>
    <mat-tab-group (selectedTabChange)="onSelectedTabChange($event)" color="accent" mat-align-tabs="center">
      <mat-tab label="tab_info">
        <ng-template matTabLabel>
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="2px">
            <span>{{ 'DEVICES.TABS.INFO' | translate | uppercase }}</span>
          </div>
        </ng-template>
        <iot4bos-ui-device-info
          (configureDeviceConnector)="onConfigureDeviceConnector($event)"
          [canCreate]="canCreate"
          [canDelete]="canDelete"
          [canReadAuditTrail]="canReadAuditTrail"
          [canRead]="canRead"
          [canUpdate]="canUpdate"
          [site]="site$ | async"
        ></iot4bos-ui-device-info>
      </mat-tab>
      <mat-tab class="mat-tab" label="tab_variables">
        <ng-template mat-tab-label>
          {{ 'DEVICES.TABS.DEVICE_VARIABLES' | translate | uppercase }}
        </ng-template>
        <ng-template matTabContent>
          <iot4bos-ui-device-variables-table fxFlex fxLayout="column" style="padding: 10px 20px"></iot4bos-ui-device-variables-table>
        </ng-template>
      </mat-tab>
      @if (canReadEvent) {
        <mat-tab label="tab_events">
          <ng-template matTabLabel>
            <div fxLayout="row" fxLayoutAlign="start center">
              <span>{{ 'IOT_DICTIONARY.device-events' | translate | uppercase }}</span>
            </div>
          </ng-template>
          <ng-template matTabContent>
            <iot4bos-ui-device-events-tabs fxFlex [selectedDevice]="device$ | async" [userPermissions]="userPermissions"></iot4bos-ui-device-events-tabs>
          </ng-template>
        </mat-tab>
      }
      <mat-tab class="mat-tab" label="tab_call_logs">
        <ng-template mat-tab-label>
          {{ 'DEVICES.TABS.CALL_LOG' | translate | uppercase }}
        </ng-template>
        <ng-template matTabContent>
          <iot4bos-ui-device-call-log [deviceName]="(device$ | async).name" fxFlex fxLayout="column" style="padding: 10px 20px"></iot4bos-ui-device-call-log>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </mat-sidenav-content>
  <mat-sidenav [opened]="dataGuardOpened()" mode="over" position="end">
    @defer (when dataGuardOpened()) {
      <iot-platform-feature-data-guard />
    }
  </mat-sidenav>
</mat-sidenav-container>
