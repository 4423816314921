import { ClipboardModule } from '@angular/cdk/clipboard';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DetailPopupModule, PhoneNumberInputComponent, ToolbarV2Module } from '@iot-platform/iot-platform-ui';
import { DateFormatPipe, InfoDisplayPipe, NumberFormatPipe, TruncatePipe, UserNamePipe } from '@iot-platform/pipes';
import { SharedModule } from '@iot-platform/shared';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { UserProfileApiKeysInfoPopupComponent } from './components/user-profile-api-keys-info-popup/user-profile-api-keys-info-popup.component';
import { UserProfileApiKeysComponent } from './components/user-profile-api-keys/user-profile-api-keys.component';
import { UserProfileInfoFormComponent } from './components/user-profile-info-form/user-profile-info-form.component';
import { UserProfilePreferencesFormComponent } from './components/user-profile-preferences-form/user-profile-preferences-form.component';
import { UserRelatedBusinessProfilesModule } from './components/user-related-business-profiles/user-related-business-profiles.module';
import { UserProfileShellComponent } from './containers/user-profile-shell/user-profile-shell.component';
import { ProfileEffects } from './state/effects/profile.effects';
import * as fromUserProfile from './state/reducers';

@NgModule({
  declarations: [
    UserProfileShellComponent,
    UserProfilePreferencesFormComponent,
    UserProfileApiKeysComponent,
    UserProfileApiKeysInfoPopupComponent,
    UserProfileInfoFormComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    TranslateModule,
    StoreModule.forFeature(fromUserProfile.userProfileFeatureKey, fromUserProfile.reducers),
    EffectsModule.forFeature([ProfileEffects]),
    InfoDisplayPipe,
    DateFormatPipe,
    NumberFormatPipe,
    UserRelatedBusinessProfilesModule,
    DetailPopupModule,
    TruncatePipe,
    ClipboardModule,
    PhoneNumberInputComponent,
    ToolbarV2Module,
    UserNamePipe
  ],
  providers: []
})
export class ProfileModule {}
