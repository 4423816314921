export enum AssetModelName {
  BLANK = 'blank',
  BULK = 'bulk',
  ONSITE = 'onsite',
  PG = 'pg',
  CONTAINER = 'container',
  TRAILER = 'trailer',
  WAGON = 'wagon'
}

export enum AssetCategoryName {
  FIXED_ASSET = 'fixed_asset',
  MOBILE_ASSET = 'mobile_asset'
}

export interface AssetModel {
  name: string;
  category: AssetCategory;
  assetOptionalProperties?: string[];
  icon?: string;
}

export const QUANTITIES: (string | null)[] = [
  null,
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '2x1',
  '2x2',
  '2x3',
  '2x4',
  '2x5',
  '2x6',
  '2x7',
  '2x8',
  '2x9',
  '2x10',
  '2x11',
  '2x12',
  '2x13',
  '2x14',
  '2x15'
];

type AssetCategory = { name: AssetCategoryName.FIXED_ASSET; icon: 'asset' } | { name: AssetCategoryName.MOBILE_ASSET; icon: 'mobile_asset' };

// Categories
const fixedCategory: AssetCategory = { name: AssetCategoryName.FIXED_ASSET, icon: 'asset' };
const mobileCategory: AssetCategory = { name: AssetCategoryName.MOBILE_ASSET, icon: 'mobile_asset' };

// Models
const blankModel: AssetModel = { name: AssetModelName.BLANK, category: fixedCategory };
const bulkModel: AssetModel = { name: AssetModelName.BULK, category: fixedCategory, assetOptionalProperties: ['businessId', 'shipTo', 'product1'] };
const onsiteModel: AssetModel = { name: AssetModelName.ONSITE, category: fixedCategory, assetOptionalProperties: ['businessId'] };
const pgModel: AssetModel = {
  name: AssetModelName.PG,
  category: fixedCategory,
  assetOptionalProperties: ['product1', 'product2', 'quantity1', 'quantity2', 'deliveryDate']
};
const containerModel: AssetModel = { name: AssetModelName.CONTAINER, category: mobileCategory };
const trailerModel: AssetModel = { name: AssetModelName.TRAILER, category: mobileCategory };
const wagonModel: AssetModel = { name: AssetModelName.WAGON, category: mobileCategory };

export const REGULAR_ASSET_MODELS: AssetModel[] = [blankModel, bulkModel, onsiteModel, pgModel];
export const MOBILE_FLEET_ASSET_MODELS: AssetModel[] = [containerModel, trailerModel, wagonModel];
export const ASSET_MODELS_FOR_ASSET_CREATION_FROM_TEMPLATE: AssetModel[] = [
  {
    name: AssetModelName.BULK,
    icon: 'assets/images/asset-creation-models/' + AssetModelName.BULK + '.png',
    category: { name: AssetCategoryName.FIXED_ASSET, icon: 'asset' }
  },
  {
    name: AssetModelName.ONSITE,
    icon: 'assets/images/asset-creation-models/' + AssetModelName.ONSITE + '.png',
    category: { name: AssetCategoryName.FIXED_ASSET, icon: 'asset' }
  },
  {
    name: AssetModelName.PG,
    icon: 'assets/images/asset-creation-models/' + AssetModelName.PG + '.png',
    category: { name: AssetCategoryName.FIXED_ASSET, icon: 'asset' }
  }
];

export const ASSET_TEMPLATES_MODELS = [
  AssetModelName.BULK,
  AssetModelName.ONSITE,
  AssetModelName.PG,
  AssetModelName.CONTAINER,
  AssetModelName.TRAILER,
  AssetModelName.WAGON
];
