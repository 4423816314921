<mat-card class="dialog-card">
  <mat-card-title class="dialog-card-header">
    <mat-toolbar class="dialog-toolbar" color="accent" fxLayout="row" fxLayoutAlign="space-between center">
      <h1 class="dialog-card-title">{{ 'PO_EVENTS.CREATION_CONFIGURATION_POPUP.CONFIGURE_TITLE' | translate: { ruleName: data.element.name } }}</h1>
      <button (click)="close()" class="regular-round-button" mat-icon-button>
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar>
  </mat-card-title>
  <mat-card-content class="dialog-card-content">
    <form [formGroup]="poEventConfigureForm" fxLayout="column">
      <section class="form-field-container" fxLayout="column" fxLayoutGap="10px">
        <div fxLayout="row">
          <mat-slide-toggle formControlName="active">{{ 'PO_EVENTS.CREATION_CONFIGURATION_POPUP.ACTIVE' | translate }} </mat-slide-toggle>
        </div>
        <iot-platform-ui-detail-popup-display-property
          [property]="'IOT_DICTIONARY.RULE_TYPE' | translate"
          [value]="data.element.algo"
        ></iot-platform-ui-detail-popup-display-property>
        <div fxLayout="row" fxLayoutGap="20px">
          <mat-form-field color="accent" fxFlex>
            <mat-label>{{ 'PO_EVENTS.CREATION_CONFIGURATION_POPUP.CLASS' | translate }}</mat-label>
            <mat-select formControlName="class">
              @for (supportedClass of selectedAlgo?.supportedClasses; track supportedClass) {
                <mat-option [value]="supportedClass">{{ supportedClass }}</mat-option>
              }
            </mat-select>
          </mat-form-field>
          <mat-form-field color="accent" fxFlex>
            <mat-label>{{ 'PO_EVENTS.CREATION_CONFIGURATION_POPUP.SEVERITY' | translate }}</mat-label>
            <mat-select formControlName="severity">
              @for (sev of severities$ | async; track sev) {
                <mat-option [value]="sev">{{ 'EVENTS.SEVERITIES.' + sev | translate }}</mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
        <div fxFlex fxLayout="columns" fxLayoutGap="20px">
          @for (param of selectedAlgo?.params; track param) {
            <div fxFlex>
              @if (param.value.type === 'string' && param.value.hasOwnProperty('enum')) {
                <iot4bos-ui-string-input (valueChange)="onParamValueChange($event)" [data]="getStringData(param)"></iot4bos-ui-string-input>
              }
              @if (param.value.type === 'string' && !param.value.hasOwnProperty('enum')) {
                <iot4bos-ui-text-input (valueChange)="onParamValueChange($event)" [data]="getStringData(param)"></iot4bos-ui-text-input>
              }
              @if (param.value.type === 'integer' && param.value.hasOwnProperty('enum')) {
                <iot4bos-ui-string-input (valueChange)="onParamValueChange($event)" [data]="getStringData(param)"></iot4bos-ui-string-input>
              }
              @if (param.value.type === 'number') {
                <iot4bos-ui-number-input (valueChange)="onParamValueChange($event)" [data]="getData(param)"></iot4bos-ui-number-input>
              }
              @if (param.value.type === 'integer' && !param.value.hasOwnProperty('enum')) {
                <iot4bos-ui-number-input (valueChange)="onParamValueChange($event)" [data]="getData(param)"></iot4bos-ui-number-input>
              }
            </div>
          }
        </div>
        <div fxFlex="25%">
          @if (selectedAlgo && selectedAlgo.concepts.length > 1) {
            <mat-form-field color="accent">
              <mat-label>{{ 'PO_EVENTS.CREATION_CONFIGURATION_POPUP.CONCEPT' | translate }}</mat-label>
              <mat-select (selectionChange)="onConceptChange($event)" formControlName="conceptList">
                @for (concept of selectedAlgo.concepts; track concept) {
                  <mat-option [value]="concept">{{ 'IOT_DICTIONARY.' + concept | translate }}</mat-option>
                }
              </mat-select>
            </mat-form-field>
          }
          @if (selectedAlgo && selectedAlgo.concepts.length === 1) {
            <mat-form-field class="" color="accent">
              <mat-label>{{ 'PO_EVENTS.CREATION_CONFIGURATION_POPUP.CONCEPT' | translate }}</mat-label>
              <input #algoInput formControlName="conceptSolo" matInput />
            </mat-form-field>
          }
        </div>
        <mat-slide-toggle formControlName="backToNormal" fxFlex>{{ 'PO_EVENTS.CREATION_CONFIGURATION_POPUP.BACK_TO_NORMAL' | translate }} </mat-slide-toggle>
      </section>
      @if ((pathToFilters$ | async) && selectedAlgo && currentConcept) {
        <iot-platform-ui-filter-engine
          #filterEngine
          (applyFilters)="onApplyFilters($event)"
          [clearAppliedFilters]="clearAppliedFilters$ | async"
          [currentFavoriteView]="currentFavoriteView$ | async"
          [currentFilters]="currentFilters$ | async"
          [expanded]="true"
          [masterView]="pathToFilters$ | async"
          fxFlex
        >
        </iot-platform-ui-filter-engine>
      }
      <p class="dialog-form-tips">{{ 'ASSETS.VARIABLE_FORM.MANDATORY_FIELDS' | translate }}</p>
    </form>
  </mat-card-content>
  <mat-card-actions class="dialog-card-actions" fxLayoutAlign="end center">
    <button (click)="close()" class="button-regular" mat-button>{{ 'IOT_DICTIONARY.CANCEL' | translate }}</button>
    <button (click)="configure()" [disabled]="shouldDisableConfigureButton" class="button-regular" color="accent" mat-raised-button>
      {{ 'IOT_DICTIONARY.SAVE' | translate }}
    </button>
  </mat-card-actions>
</mat-card>
